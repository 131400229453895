import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { formatDateThai } from "../common/formatDateThai";
import Swal from "sweetalert2";
import CustomDatePicker from "../common/customDatePicker";
import "./privateTour.css";

const PrivateTour = () => {
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    tel: "",
    moreDetail: "",
    line: "",
    price: "ต่ำกว่า 10,000 บาท",
    tour: "ทัวร์ส่วนตัว",
    start: new Date(),
    end: new Date(),
    guests: 1,
  });

  const url = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setFormData({ ...formData, start: date });
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setFormData({ ...formData, end: date });
  };

  const numberOfDays =
    startDate && endDate
      ? Math.abs(Math.ceil((endDate - startDate) / (24 * 60 * 60 * 1000)))
      : null;

  const handleSubmitModal = async (e) => {
    e.preventDefault();
    const formDataToSend = {
      ...formData,
    };
    try {
      await axios.post(`${url}formdetail`, formDataToSend);
      setShowModal(false);

      Swal.fire({
        icon: "success",
        title: "เรียบร้อย!!",
        text: "เราจะทำการติดต่อกลับให้เร็วที่สุด",
        customClass: {
          popup: "custom-sweetalert",
          confirmButton: "custom-confirm-button",
        },
        buttonsStyling: true,
        confirmButtonText: "ตกลง",
      });
    } catch (error) {
      console.error("Error submitting form data:", error);

      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: "เกิดข้อผิดพลาดในการส่งข้อมูลแบบฟอร์ม กรุณาลองอีกครั้ง",
        customClass: {
          popup: "custom-sweetalert",
          confirmButton: "custom-confirm-button",
        },
        buttonsStyling: true,
        confirmButtonText: "ตกลง",
      });
    }

    setFormData({
      name: "",
      surname: "",
      email: "",
      tel: "",
      moreDetail: "",
      line: "",
      tour: "ทัวร์ส่วนตัว",
      price: "ต่ำกว่า 10,000 บาท",
      start: new Date(),
      end: new Date(),
      guests: 1,
    });
  };

  useEffect(() => {
    const sections = document.querySelectorAll(".section");
    const windowHeight = window.innerHeight;

    const checkVisibility = () => {
      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top < windowHeight / 1.5) {
          section.classList.add("visible");
        } else {
          section.classList.remove("visible");
        }
      });
    };

    window.addEventListener("scroll", checkVisibility);
    window.addEventListener("resize", checkVisibility);

    checkVisibility();

    return () => {
      window.removeEventListener("scroll", checkVisibility);
      window.removeEventListener("resize", checkVisibility);
    };
  }, []);

  return (
    <div className="private-tour-container">
      <div className="section normal-bg">
        <h1 className="section-title">อยากเที่ยวที่ไหน เพียงแค่บอกเรา</h1>
        <p></p>
      </div>
      <div className="section green-bg">
        <div className="section-content">
          <img
            src="https://storage.googleapis.com/thanyaporn_tour/PrivateImgs/airplane-blue-passports-310x280.jpg"
            alt=""
            className="custom-img"
          />
          <div className="text-content">
            <h2>ปรับแต่งทัวร์ส่วนตัว</h2>
            <p>
              คุณอยากไปที่ไหน คุณสะดวกเดินทางเวลาไหน งบประมาณคุณมีเท่าไหร่
              เพียงแค่คุณบอกเรา เราจัดการให้
            </p>
          </div>
        </div>
      </div>
      <div className="section normal-bg">
        <div className="section-content">
          <div className="private-card-container">
            <div className="private-card">
              <img
                src="https://storage.googleapis.com/thanyaporn_tour/PrivateImgs/money.png"
                alt=""
                className="private-card-img"
              />
              <h3>จัดการงบประมาณด้วยตนเอง</h3>
              <p>ให้คุณตัดสินใจ ราคา ที่เหมาะสมสำหรับคุณที่สุดด้วยตนเอง</p>
            </div>
            <div className="private-card">
              <img
                src="https://storage.googleapis.com/thanyaporn_tour/PrivateImgs/quality.png"
                alt=""
                className="private-card-img"
              />
              <h3>คุณภาพดีที่สุด</h3>
              <p>เราพร้อมบริการ เพื่อให้คุณพบกับประสบการณ์ที่ดีที่สุด</p>
            </div>
            <div className="private-card">
              <img
                src="https://storage.googleapis.com/thanyaporn_tour/PrivateImgs/timetable.png"
                alt=""
                className="private-card-img"
              />
              <h3>เวลา</h3>
              <p>ไม่ต้องห่วงเรื่องเวลา คุณว่างตอนไหน ไปตอนนั้น</p>
            </div>
          </div>
        </div>
      </div>

      <div className="section green-bg">
        <div className="section-content">
          <div className="text-content">
            <h2>ทัวร์ส่วนตัวที่ปลอดภัย</h2>
            <p>
              ความปลอดภัยของคุณเป็นสำคัญอยู่เสมอ
              ทัวร์ของเราจะมีการดูแลและความปลอดภัยตลอดเวลา
            </p>
          </div>
          <img
            src="https://storage.googleapis.com/thanyaporn_tour/PrivateImgs/TourGuide.png"
            alt=""
            className="custom-img"
          />
        </div>
      </div>

      <div className="container-detail">
        <div className="sub-container-main">
          <div className="sub-container-content">
            <div className="form-sub-container">
              <div className="container-form-detail">
                <div className="head-detail">
                  <h3 style={{ fontSize: "36px" }}>
                    อยากไปเที่ยวไหน เพียงแค่บอกเรา !
                  </h3>
                </div>
                <form className="form" onSubmit={handleSubmit}>
                  <div className="form-container">
                    <ul>
                      <div className="form-group">
                        <label htmlFor="city">เมืองที่ต้องการไป</label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          placeholder="Shirakawa-go"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="price">งบประมาณต่อคน</label>
                        <select
                          className="form-control private-control"
                          id="price"
                          name="price"
                          value={formData.price}
                          onChange={handleChange}
                        >
                          <option value="ต่ำกว่า 10,000 บาท">
                            ต่ำกว่า 10,000 บาท
                          </option>
                          <option value="10,001 - 20,000 บาท">
                            10,001 - 20,000 บาท
                          </option>
                          <option value="20,001 - 30,000 บาท">
                            20,001 - 30,000 บาท
                          </option>
                          <option value="30,001 - 40,000 บาท">
                            30,001 - 40,000 บาท
                          </option>
                          <option value="40,001 - 50,000 บาท">
                            40,001 - 50,000 บาท
                          </option>
                          <option value="50,001 - 60,000 บาท">
                            50,001 - 60,000 บาท
                          </option>
                          <option value="มากกว่า 60,000 บาท">
                            มากกว่า 60,000 บาท
                          </option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="guests">จำนวนคน</label>
                        <select
                          className="form-control private-control"
                          id="guests"
                          name="guests"
                          value={formData.guests}
                          onChange={handleChange}
                        >
                          <option value="1">1 คน</option>
                          <option value="2">2 คน</option>
                          <option value="3">3 คน</option>
                          <option value="4">4 คน</option>
                          <option value="5">5 คน</option>
                          <option value="6">6 คน</option>
                          <option value="7">7 คน</option>
                          <option value="8">8 คน</option>
                          <option value="9">9 คน</option>
                          <option value="10">10 คน</option>
                          <option value="11">11 คน</option>
                          <option value="12">12 คน</option>
                          <option value="13">13 คน</option>
                          <option value="14">14 คน</option>
                          <option value="15">15 คน</option>
                          <option value="มากกว่า 15">มากกว่า 15 คน</option>
                        </select>
                      </div>
                    </ul>
                    <ul>
                      <div className="form-group">
                        <label htmlFor="dateIn">วันเดินทางไป</label>
                        <CustomDatePicker
                          name="start"
                          className="form-control date-input"
                          startDateProp={startDate}
                          onChangeStart={handleStartDateChange}
                          dateFormat="yyyy-MM-dd"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="dateOut">วันเดินทางกลับ</label>
                        <CustomDatePicker
                          name="end"
                          className="form-control date-input"
                          startDateProp={endDate}
                          onChangeEnd={handleEndDateChange}
                          dateFormat="yyyy-MM-dd"
                        />
                      </div>

                      <div className="form-group">
                        <label>จำนวนวัน</label>{" "}
                        <input
                          value={numberOfDays || 0}
                          className="form-control custom-input"
                          readOnly
                        />
                      </div>
                    </ul>
                    <ul>
                      <div className="form-group">
                        <label htmlFor="name">ชื่อ</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                          onInvalid={(e) =>
                            e.target.setCustomValidity("กรุณากรอกชื่อ")
                          }
                          onInput={(e) => e.target.setCustomValidity("")}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="surname">นามสกุล</label>
                        <input
                          type="text"
                          className="form-control"
                          id="surname"
                          name="surname"
                          value={formData.surname}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="tel">เบอร์โทรศัพท์</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="tel"
                          name="tel"
                          value={formData.tel}
                          onChange={handleChange}
                          required
                          onInvalid={(e) =>
                            e.target.setCustomValidity("กรุณาเบอร์โทรศัพท์")
                          }
                          onInput={(e) => e.target.setCustomValidity("")}
                        />
                      </div>
                    </ul>
                    <ul>
                      <div className="form-group">
                        <label htmlFor="line">ไอดีไลน์</label>
                        <input
                          type="text"
                          className="form-control"
                          id="line"
                          name="line"
                          value={formData.line}
                          onChange={handleChange}
                          required
                          onInvalid={(e) =>
                            e.target.setCustomValidity("กรุณากรอกไอดีไลน์")
                          }
                          onInput={(e) => e.target.setCustomValidity("")}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">อีเมลล์</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </ul>

                    <div className="form-group">
                      <label htmlFor="moreDetail">ข้อมูลเพิ่มเติม</label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="moreDetail"
                        name="moreDetail"
                        value={formData.moreDetail}
                        onChange={handleChange}
                        rows="5"
                      ></textarea>
                    </div>

                    <div className="submit-button-container">
                      <button className="btn-detail">ส่งข้อมูล</button>
                    </div>
                  </div>

                  <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>ข้อมูลการจอง</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="modal-container">
                        <p>
                          <span className="label-data">ชื่อ: </span>
                          {formData.name}
                        </p>
                        <p>
                          <span className="label-data">นามสกุล: </span>
                          {formData.surname}
                        </p>
                        <p>
                          <span className="label-data">งบประมาณต่อคน: </span>
                          {formData.price}
                        </p>
                        <p>
                          <span className="label-data">จำนวนคน: </span>
                          {formData.guests} ท่าน
                        </p>
                        <p>
                          <span className="label-data">เช็คอิน: </span>
                          {formData.start && formatDateThai(formData.start)}
                        </p>
                        <p>
                          <span className="label-data"> เช็คเอาท์: </span>
                          {formData.end && formatDateThai(formData.end)}
                        </p>
                        <p>
                          <span className="label-data">ไอดีไลน์: </span>
                          {formData.line}
                        </p>
                        <p>
                          <span className="label-data">เบอร์โทรศัพท์: </span>
                          {formData.tel}
                        </p>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="btn-detail-modal-cancel"
                        variant="secondary"
                        onClick={() => setShowModal(false)}
                      >
                        ยกเลิก
                      </Button>
                      <Button
                        className="btn-detail-modal"
                        variant="primary"
                        onClick={handleSubmitModal}
                      >
                        ยืนยัน
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateTour;
