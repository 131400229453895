import THAI from "../logo/THAI.png";
import ANA from "../logo/ANA.png";
import AIRASIAX from "../logo/AIRASIAX.png";
import ZIPAIR from "../logo/ZIPAIR.png";
import JAL from "../logo/JAL.png";

const logoMapping = {
  img: {
    THAI: THAI,
    ANA: ANA,
    AIRASIAX: AIRASIAX,
    ZIPAIR: ZIPAIR,
    JAL: JAL,
  },
  text: {
    THAI: "การบินไทย",
    ANA: "All Nippon Airways",
    AIRASIAX: "ไทย แอร์เอเชียร์ เอ้กซ์",
    ZIPAIR: "ซิปแอร์",
    JAL: "Japan Airline",
  },
};

export default logoMapping;
