import { FaPhone, FaFacebookF } from "react-icons/fa";
import Line from "../../assets/photo/Logo/line.png";

const Social = () => {
  return (
    <div className="social-icons">
      <a
        href="https://www.facebook.com/thanyaporntours"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebookF />
      </a>
      <a href="tel:+66961679906">
        <FaPhone />
        <p className="text-icon">TH</p>
      </a>
      <a href="tel:+819044024607">
        <FaPhone />
        <p className="text-icon">JP</p>
      </a>
      <a
        href="https://line.me/R/ti/p/@thanyaporntour?from=page&searchId=thanyaporntour"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="line" src={Line} alt="Logo" />
      </a>
    </div>
  );
};

export default Social;
