import React, { useState, useEffect } from "react";
import { checkAdminRole } from "./authUtils";
import axios from "axios";

const FbDeleteTour = () => {
  const isAdmin = checkAdminRole();
  const [selectedTitle, setSelectedTitle] = useState("");
  const [tourTitles, setTourTitles] = useState([]);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchTourTitles();
  }, []);

  const fetchTourTitles = async () => {
    try {
      const response = await axios.get(`${url}fbdeletetour`);
      const titles = response.data;
      setTourTitles(titles);
    } catch (error) {
      console.error("Error fetching tour titles:", error);
    }
  };

  const handleDelete = async () => {
    try {
      if (!selectedTitle) {
        alert("Please select a tour title to delete.");
        return;
      }

      const response = await axios.delete(
        `${url}fbdeletetour/${encodeURIComponent(selectedTitle)}`
      );

      if (response.status === 200) {
        alert("Tour deleted successfully");

        fetchTourTitles();
      } else {
        alert("Failed to delete tour.");
      }
    } catch (error) {
      console.error("Error deleting tour:", error);
      alert("An error occurred while deleting the tour.");
    }
  };

  return (
    <div className="delete-tour-container">
      {isAdmin ? (
        <>
          <h2>ลบทัวร์อื่น ๆ</h2>
          <div>
            <label>เลือกทัวร์ดูอื่น ๆที่จะลบ:</label>
            <select
              className="tour-select"
              value={selectedTitle}
              onChange={(e) => setSelectedTitle(e.target.value)}
            >
              <option value="">เลือกทัวร์...</option>
              {tourTitles.map((title, index) => (
                <option key={index} value={title}>
                  {title}
                </option>
              ))}
            </select>
          </div>
          <button className="delete-button" onClick={handleDelete}>
            ลบทัวร์
          </button>
        </>
      ) : (
        <p>You are not authorized to access this page.</p>
      )}
    </div>
  );
};

export default FbDeleteTour;
