import React from "react";
import Logo from "../../assets/photo/Logo/logo_black.png";
import AdminLoginStatus from "./adminLoginStatus";
import { checkAdminRole } from "../admin/authUtils";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const isAdmin = checkAdminRole();
  const logoUrl = Logo;

  return (
    <div className=" header bg-light border-bottom ">
      <div className="header-logo">
        <img
          src={logoUrl}
          alt="Logo"
          className="header-logo"
          style={{ width: "60px", height: "auto" }}
        />

        <div className="header-text fst-italic">
          <a
            className="navbar-brand "
            href="/"
            style={{
              marginLeft: "10px",
              wordBreak: "break-all",
              fontSize: "20px",
            }}
          >
            Thanyaporn Japan Tours
          </a>
        </div>
        {isAdmin && (
          <div className="admin-status">
            <AdminLoginStatus />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
