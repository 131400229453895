import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import logoMapping from "../common/logo";
import Breadcrumbs from "../common/breadCrumbs";
import "./detail.css";

const FootballDetail = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [breadcrumbs] = useState([
    { label: "หน้าหลัก", path: "/" },
    { label: "ทัวร์อื่น ๆ", path: "/tour" },
    { label: "รายละเอียด", path: `/tour/${id}` },
  ]);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handlePDFButtonClick = () => {
    const pdfId = `${data.pathPDF}`;
    window.open(pdfId, "_blank");
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}fbtourdetail/${id}`);
      const processedData = {
        ...response.data,
      };

      setData(processedData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleBreadcrumbClick = (path) => {
    navigate(path);
  };

  return (
    <div class="container-main">
      <div className="container-detail">
        <div className="detail-header">
          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            onBreadcrumbClick={handleBreadcrumbClick}
          />
        </div>
        <div className="container-title">
          <h2>{data.title}</h2>
        </div>
        <div className="sub-container-main">
          <div className="image-container">
            <img src={`${data.path}`} className="img-detail" alt="..." />
          </div>
          <div className="sub-container-content">
            <div className="sub-container-1">
              <div className="sub-container-detail-1">
                <div className="content-container">
                  <ul className="content-list">
                    <span className="label-date">ระยะเวลา:</span>
                    <p>
                      {data.day} วัน {data.night} คืน
                    </p>

                    <span className="label-airlines">สายการบิน:</span>
                    <p>{logoMapping.text[data.logo]}</p>
                  </ul>

                  <ul className="content-list">
                    <span className="label-meal">อาหาร:</span>
                    <p>{data.meal} มื้อ</p>
                    <span className="label-price">ราคา:</span>
                    <p>
                      {data.price ? data.price.toLocaleString() : "ไม่พบราคา"}{" "}
                      บาท/ท่าน
                    </p>
                  </ul>
                  <ul className="content-button">
                    <p className="img">
                      {logoMapping.img[data.logo] ? (
                        <img
                          src={logoMapping.img[data.logo]}
                          className="airways-logo-detail"
                          alt="Airways Logo"
                        />
                      ) : (
                        <img
                          src={data.logo}
                          className="airways-logo-detail"
                          alt="Airways Logo"
                        />
                      )}
                    </p>
                    <Link to={`/fbform/${data._id}`}>
                      <button
                        className="btn-detail-tour"
                        style={{
                          border: "1px solid",
                          borderRadius: "10px",
                        }}
                      >
                        สนใจทัวร์
                      </button>
                    </Link>
                  </ul>
                </div>
              </div>
              <div className="sub-container-detail-2">
                <span className="label-highlight">Highlight</span>
                <p dangerouslySetInnerHTML={{ __html: data.highlight }} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="detail-tour">
        <div className="header-detail">
          <h5 className="title-detail">โปรแกรมทัวร์</h5>{" "}
          <div className="btn-container">
            <button className="btn-detail-pdf" onClick={handlePDFButtonClick}>
              ดูโปรแกรมทัวร์ (PDF)
            </button>
          </div>
        </div>
        <div className="detail-container">
          <div className="timeline-detail">
            {data.days &&
              data.days.map((dayDetail, index) =>
                dayDetail.morning || dayDetail.midday || dayDetail.evening ? (
                  <div key={index} className="timeline-box">
                    <div className="timeline-date">วันที่ {index + 1}</div>
                    <div className="timeline-period">
                      <div className="timeline-left">
                        {dayDetail.morning && (
                          <div
                            className="timeline-morning"
                            style={{ fontWeight: "bold", fontSize: "20px" }}
                          >
                            ช่วงเช้า
                          </div>
                        )}
                        {dayDetail.morning && (
                          <div
                            className="timeline-morning-description"
                            dangerouslySetInnerHTML={{
                              __html: dayDetail.morning,
                            }}
                          ></div>
                        )}
                        {dayDetail.midday && (
                          <div
                            className="timeline-midday"
                            style={{ fontWeight: "bold", fontSize: "20px" }}
                          >
                            กลางวัน
                          </div>
                        )}
                        {dayDetail.midday && (
                          <div
                            className="timeline-midday-description"
                            dangerouslySetInnerHTML={{
                              __html: dayDetail.midday,
                            }}
                          ></div>
                        )}
                        {dayDetail.evening && (
                          <div
                            className="timeline-evening"
                            style={{ fontWeight: "bold", fontSize: "20px" }}
                          >
                            ช่วงบ่าย
                          </div>
                        )}
                        {dayDetail.evening && (
                          <div
                            className="timeline-evening-description"
                            dangerouslySetInnerHTML={{
                              __html: dayDetail.evening,
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FootballDetail;
