import React, { useState } from "react";

const SearchBar = ({ onSearch }) => {
  const [tourType, setTourType] = useState("");
  const [airline, setAirline] = useState("");
  const [city, setCity] = useState("");
  const [keywords, setKeywords] = useState("");

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    const searchData = {
      tourType,
      airline,
      city,
      keywords,
    };
    onSearch(searchData);
  };

  return (
    <form className="search-form" onSubmit={handleSearchSubmit}>
      <div className="search-group">
        <select
          className="search-select"
          value={tourType}
          onChange={(e) => setTourType(e.target.value)}
        >
          <option value="">ทัวร์</option>
          <option value="japantour">ทัวร์ญี่ปุ่น</option>
          <option value="footballtour">ทัวร์อื่น ๆ</option>
        </select>
      </div>
      <div className="search-group">
        <select
          className="search-select"
          value={airline}
          onChange={(e) => setAirline(e.target.value)}
        >
          <option value="">สายการบิน</option>
          <option value="THAI">การบินไทย</option>
          <option value="ANA">ANA</option>
          <option value="AIRASIAX">AirAsiaX</option>
          <option value="ZIPAIR">ZIPAIR</option>
          <option value="JAL">Japan Airline</option>
        </select>
      </div>
      <div className="search-group">
        <input
          className="search-input"
          type="text"
          placeholder="เมือง"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
      </div>
      <div className="search-group">
        <input
          className="search-input"
          type="text"
          placeholder="ค้นหาด้วยคำ"
          value={keywords}
          onChange={(e) => setKeywords(e.target.value)}
        />
      </div>
      <button className="search-button" type="submit">
        ค้นหา
      </button>
    </form>
  );
};

export default SearchBar;
