import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./admin.css";
import { checkAdminRole } from "./authUtils";

const DataList = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const isAdmin = checkAdminRole();
  const url = process.env.REACT_APP_API_URL;

  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}formdetail`);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  const handleRowClick = (id) => {
    navigate(`/detail/${id}`);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(data.length / rowsPerPage);

  return (
    <div className="data-list-container">
      {isAdmin && (
        <>
          <table className="data-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Tour</th>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((rowData) => (
                <tr
                  key={rowData._id}
                  onClick={() => handleRowClick(rowData._id)}
                  className="data-row"
                >
                  <td className="td-name">{rowData.name}</td>
                  <td>
                    {rowData.tour ? (
                      <span>{rowData.tour}</span>
                    ) : (
                      <span>{rowData.moreDetail}</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination">
            {currentPage > 1 && (
              <button
                className="pagination-button"
                onClick={() => paginate(currentPage - 1)}
              >
                ย้อนกลับ
              </button>
            )}

            <span>{`Page ${currentPage} of ${totalPages}`}</span>

            {currentPage < totalPages && (
              <button
                className="pagination-button"
                onClick={() => paginate(currentPage + 1)}
              >
                ต่อไป
              </button>
            )}
          </div>
        </>
      )}

      {!isAdmin && <p>You are not authorized to access this page.</p>}
    </div>
  );
};

export default DataList;
