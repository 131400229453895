import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import logoMapping from "../common/logo";
import Breadcrumbs from "../common/breadCrumbs";
import { RaceBy } from "@uiball/loaders";
import "./tour.css";

const Football = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayCount, setDisplayCount] = useState(4);
  const navigate = useNavigate();
  const location = useLocation();
  const [filteredData, setFilteredData] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const tourType = searchParams.get("tourType");
  const airline = searchParams.get("airline");
  const city = searchParams.get("city");
  const keywords = searchParams.get("keywords");
  const url = process.env.REACT_APP_API_URL;
  const [breadcrumbs] = useState([
    { label: "หน้าหลัก", path: "/" },
    { label: "ทัวร์อื่น ๆ", path: "/tour" },
  ]);

  useEffect(() => {
    fetchData();
  }, [displayCount, tourType, airline, city, keywords]);

  const fetchData = async () => {
    const startTime = Date.now();
    try {
      const response = await axios.get(`${url}footballtour`, {
        params: {
          limit: displayCount,
          tourType,
          airline,
          city,
          keywords,
        },
      });
      const responseData = Array.isArray(response.data) ? response.data : [];
      setData(responseData);
      setFilteredData(responseData);

      // setData(response.data);
      // setFilteredData(response.data);

      const endTime = Date.now();
      if (endTime - startTime < 100) {
        setTimeout(() => {
          setLoading(false);
        }, 400);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCardClick = (event) => {
    navigate(`/tour/${event._id}`);
  };

  const handleViewMoreClick = () => {
    setDisplayCount((prevCount) => prevCount + 4);
  };

  const handleBreadcrumbClick = (path) => {
    navigate(path);
  };

  return (
    <div>
      <div className="container">
        {" "}
        {loading ? (
          <div className="loader-container">
            <RaceBy size={80} lineWeight={5} speed={1.4} color="#355e3b" />
          </div>
        ) : (
          <div className="card-container">
            <div className="card-header">
              <Breadcrumbs
                breadcrumbs={breadcrumbs}
                onBreadcrumbClick={handleBreadcrumbClick}
              />
            </div>
            {data.map((item) => (
              <div key={item._id} className="card">
                <Link to={`/tour/${item._id}`} className="card-content-link">
                  <div className="card-content">
                    <div className="card-img-container">
                      <img
                        src={`${item.path}`}
                        className="card-img"
                        alt="..."
                      />
                    </div>
                    <div className="text-container ">
                      <div className="container-content">
                        <h5 className="card-title">{item.title}</h5>
                        <div className="additional">
                          <div className="airways-container">
                            <div className="logo-container">
                              {logoMapping.img[item.logo] ? (
                                <img
                                  src={logoMapping.img[item.logo]}
                                  className="airways-logo"
                                  alt="Airways Logo"
                                />
                              ) : (
                                <img
                                  src={item.logo}
                                  className="airways-logo"
                                  alt="Airways Logo"
                                />
                              )}
                            </div>
                          </div>
                          <p className="text-date d-flex align-items-center">
                            <span
                              class="bold-numbers"
                              style={{ margin: "4px" }}
                            >
                              {item.day}{" "}
                            </span>
                            วัน
                            <span
                              class="bold-numbers"
                              style={{ margin: "4px" }}
                            >
                              {item.night}
                            </span>
                            คืน
                          </p>

                          <p className="card-price d-flex align-items-center">
                            {item.price
                              ? item.price.toLocaleString()
                              : "ไม่พบราคา"}
                            /ท่าน
                          </p>
                        </div>
                        <p
                          className="card-description"
                          dangerouslySetInnerHTML={{ __html: item.highlight }}
                        ></p>
                      </div>
                      <div className="btn-detail-container">
                        <button
                          className="btn-detail"
                          onClick={() => handleCardClick(item)}
                        >
                          ดูรายละเอียด
                        </button>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
            <div className="container-button-view-more">
              <button
                className="button-view-more"
                onClick={handleViewMoreClick}
              >
                ดูทัวร์เพิ่มเติม
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Football;
