import jwt_decode from "jwt-decode";
import { getToken } from "./auth";

export const checkAdminRole = () => {
  const token = getToken();

  if (token) {
    try {
      const decodedToken = jwt_decode(token);
      const userRole = decodedToken.role;

      return userRole === "admin";
    } catch (error) {
      return false;
    }
  }

  return false;
};
