import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import logoMapping from "../common/logo";
import Swal from "sweetalert2";
import CustomDatePicker from "../common/customDatePicker";
import Breadcrumbs from "../common/breadCrumbs";
import "./form.css";

const JapanFormTour = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [guests, setGuests] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    tel: "",
    moreDetail: "",
    line: "",
    tour: "",
    start: new Date(),
    end: new Date(),
    guests: 1,
  });
  const [breadcrumbs] = useState([
    { label: "หน้าหลัก", path: "/" },
    { label: "ทัวร์ญี่ปุ่น", path: "/japantour" },
    { label: "รายละเอียด", path: `/japantour/${id}` },
    { label: "กรอกฟอร์ม", path: `/jpform/${id}` },
  ]);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}jptourdetail/${id}`);
      const processedData = {
        ...response.data,
      };
      setData(processedData);
    } catch (error) {
      console.error(error);
    }
  };

  const numberOfDays =
    startDate && endDate
      ? Math.abs(Math.ceil((endDate - startDate) / (24 * 60 * 60 * 1000)))
      : null;

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setFormData({ ...formData, start: date });
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setFormData({ ...formData, end: date });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitModal = async (e) => {
    e.preventDefault();
    const formDataToSend = {
      ...formData,
      tour: data.title,
    };
    try {
      await axios.post(`${url}formdetail`, formDataToSend);
      setShowModal(false);

      Swal.fire({
        icon: "success",
        title: "เรียบร้อย!!",
        text: "เราจะทำการติดต่อกลับให้เร็วที่สุด",
        customClass: {
          popup: "custom-sweetalert",
          confirmButton: "custom-confirm-button",
        },
        buttonsStyling: true,
        confirmButtonText: "ตกลง",
      });
    } catch (error) {
      console.error("Error submitting form data:", error);

      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: "เกิดข้อผิดพลาดในการส่งข้อมูลแบบฟอร์ม กรุณาลองอีกครั้ง",
        customClass: {
          popup: "custom-sweetalert",
          confirmButton: "custom-confirm-button",
        },
        buttonsStyling: true,
        confirmButtonText: "ตกลง",
      });
    }

    setFormData({
      name: "",
      surname: "",
      email: "",
      tel: "",
      moreDetail: "",
      line: "",
      tour: "",
      start: new Date(),
      end: new Date(),
      guests: 1,
    });
  };

  const handleBreadcrumbClick = (path) => {
    navigate(path);
  };

  const calculateTotalPrice = () => {
    if (data.price) {
      return (data.price * formData.guests).toLocaleString() + " บาท";
    } else {
      return "ไม่พบราคา";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleIncrement = () => {
    setGuests((prevGuests) => prevGuests + 1);
    setFormData((prevFormData) => ({ ...prevFormData, guests: guests + 1 }));
  };

  const handleDecrement = () => {
    if (guests > 1) {
      setGuests((prevGuests) => prevGuests - 1);
      setFormData((prevFormData) => ({ ...prevFormData, guests: guests - 1 }));
    }
  };

  return (
    <div className="form-container-main">
      <div className="container-detail">
        <div className="form-header">
          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            onBreadcrumbClick={handleBreadcrumbClick}
          />
        </div>
        <div className="sub-container-main">
          <div className="sub-container-content">
            <div className="form-sub-container">
              <div className="form-container-title">
                <h2>{data.title}</h2>
              </div>
              <div className="form-sub-container-detail">
                <div className="form-content-container">
                  <ul className="form-content-list">
                    <span className="label-date">ระยะเวลา:</span>
                    <p>
                      {data.day} วัน {data.night} คืน
                    </p>

                    <span className="label-airlines">สายการบิน:</span>
                    <p>{logoMapping.text[data.logo]}</p>
                  </ul>

                  <ul className="form-content-list">
                    <span className="label-meal">อาหาร:</span>
                    <p>{data.meal} มื้อ</p>
                    <span className="label-price">ราคา:</span>
                    <p>
                      {data.price ? data.price.toLocaleString() : "ไม่พบราคา"}{" "}
                      บาท/ท่าน
                    </p>
                  </ul>

                  <ul className="form-content-button">
                    <p className="form-img">
                      {logoMapping.img[data.logo] ? (
                        <img
                          src={logoMapping.img[data.logo]}
                          className="form-airways-logo-detail"
                          alt="Airways Logo"
                        />
                      ) : (
                        <img
                          src={data.logo}
                          className="form-airways-logo-detail"
                          alt="Airways Logo"
                        />
                      )}
                    </p>
                  </ul>
                </div>
                <div className="details-box-container">
                  <ul className="ul-guests">
                    <span className="label-guests">จำนวนผู้เข้าพัก</span>
                    <div className="guests-counter">
                      <button
                        className="minus-button"
                        onClick={handleDecrement}
                      >
                        <span>-</span>
                      </button>
                      <span className="guests-number">{guests}</span>
                      <button className="plus-button" onClick={handleIncrement}>
                        <span>+</span>
                      </button>
                    </div>
                  </ul>
                  <div className="details-box">
                    <div className="details-row">
                      <span className="details-label">จำนวน(คน)</span>
                      <span className="details-value">{guests} ท่าน</span>
                    </div>
                    <div className="details-row">
                      <span className="details-label">ราคา (บาท)</span>
                      <span className="details-value">
                        {data.price ? (
                          <p>
                            {guests} x {data.price.toLocaleString()} บาท
                          </p>
                        ) : (
                          <p>ไม่พบราคา</p>
                        )}
                      </span>
                    </div>
                    <div className="details-row">
                      <span className="details-label">ราคาทั้งหมด</span>
                      <span className="details-value">
                        {calculateTotalPrice()}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="container-form-detail">
                  <div className="head-detail">
                    <h3>รายละเอียดสำหรับการติดต่อกลับ</h3>
                  </div>
                  <form className="form" onSubmit={handleSubmit}>
                    <div className="form-container">
                      <ul>
                        <div className="form-group">
                          <label htmlFor="dateIn">วันเข้าพัก</label>
                          <CustomDatePicker
                            name="start"
                            className="form-control date-input"
                            startDateProp={startDate}
                            onChangeStart={handleStartDateChange}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="dateOut">วันเช็คเอาท์</label>
                          <CustomDatePicker
                            name="end"
                            className="form-control date-input"
                            startDateProp={endDate}
                            onChangeEnd={handleEndDateChange}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>

                        <div className="form-group">
                          <label>จำนวนวัน</label>{" "}
                          <input
                            value={numberOfDays || 0}
                            className="form-control custom-input"
                            readOnly
                          />
                        </div>
                      </ul>
                      <ul>
                        <div className="form-group">
                          <label htmlFor="name">ชื่อ</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            onInvalid={(e) =>
                              e.target.setCustomValidity("กรุณากรอกชื่อ")
                            }
                            onInput={(e) => e.target.setCustomValidity("")}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="surname">นามสกุล</label>
                          <input
                            type="text"
                            className="form-control"
                            id="surname"
                            name="surname"
                            value={formData.surname}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="tel">เบอร์โทรศัพท์</label>
                          <input
                            type="tel"
                            className="form-control"
                            id="tel"
                            name="tel"
                            value={formData.tel}
                            onChange={handleChange}
                            required
                            onInvalid={(e) =>
                              e.target.setCustomValidity("กรุณาเบอร์โทรศัพท์")
                            }
                            onInput={(e) => e.target.setCustomValidity("")}
                          />
                        </div>
                      </ul>
                      <ul>
                        <div className="form-group">
                          <label htmlFor="line">ไอดีไลน์</label>
                          <input
                            type="text"
                            className="form-control"
                            id="line"
                            name="line"
                            value={formData.line}
                            onChange={handleChange}
                            required
                            onInvalid={(e) =>
                              e.target.setCustomValidity("กรุณากรอกไอดีไลน์")
                            }
                            onInput={(e) => e.target.setCustomValidity("")}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">อีเมลล์</label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="tour">แพคเกจ</label>
                          <input
                            type="tour"
                            className="form-control custom-input"
                            id="tour"
                            name="tour"
                            defaultValue={data.title}
                            readOnly
                          />
                        </div>
                      </ul>

                      <div className="form-group">
                        <label htmlFor="moreDetail">ข้อมูลเพิ่มเติม</label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="moreDetail"
                          name="moreDetail"
                          value={formData.moreDetail}
                          onChange={handleChange}
                          rows="5"
                        ></textarea>
                      </div>

                      <div className="submit-button-container">
                        <button className="btn-detail">ส่งข้อมูล</button>
                      </div>
                    </div>

                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                      <Modal.Header closeButton>
                        <Modal.Title>ข้อมูลการจอง</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="modal-container">
                          <p>
                            <span className="label-data">ชื่อ: </span>
                            {formData.name}
                          </p>
                          <p>
                            <span className="label-data">นามสกุล: </span>
                            {formData.surname}
                          </p>
                          <p>
                            <span className="label-data">ผู้เข้าพัก: </span>
                            {formData.guests} ท่าน
                          </p>
                          <p>
                            <span className="label-data">สายการบิน: </span>
                            {logoMapping.text[data.logo]}
                          </p>
                          <p>
                            <span className="label-data">แพคเกจ: </span>
                            {data.title}
                          </p>{" "}
                          <p>
                            <span className="label-data">ไอดีไลน์: </span>
                            {formData.line}
                          </p>
                          <p>
                            <span className="label-data">เบอร์โทรศัพท์: </span>
                            {formData.tel}
                          </p>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          className="btn-detail-modal-cancel"
                          variant="secondary"
                          onClick={() => setShowModal(false)}
                        >
                          ยกเลิก
                        </Button>
                        <Button
                          className="btn-detail-modal"
                          variant="primary"
                          onClick={handleSubmitModal}
                        >
                          ยืนยัน
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JapanFormTour;
