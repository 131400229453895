import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumbs = ({ breadcrumbs, onBreadcrumbClick }) => {
  const location = useLocation();

  return (
    <div className="breadcrumb-container">
      {breadcrumbs.map((breadcrumb, index) => (
        <span key={breadcrumb.path}>
          {index > 0 && <span className="breadcrumb-separator"> / </span>}
          <span
            className={`breadcrumb-link ${
              location.pathname === breadcrumb.path ? "active" : ""
            }`}
            onClick={() => onBreadcrumbClick(breadcrumb.path)}
          >
            {breadcrumb.label}
          </span>
        </span>
      ))}
    </div>
  );
};

export default Breadcrumbs;
