import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import cars from "../common/car";
import Breadcrumbs from "../common/breadCrumbs";
import "./car.css";

const Car = () => {
  const navigate = useNavigate();
  const [breadcrumbs, setBreadcrumbs] = useState([
    { label: "หน้าหลัก", path: "/" },
    { label: "บริการรถ", path: "/carrental" },
  ]);

  const handleBreadcrumbClick = (path) => {
    navigate(path);
  };

  const handleCarClick = (car) => {
    navigate(`/carform/${car.id}`);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {" "}
      <div className="container">
        <div className="car-container">
          <div className="car-header">
            <Breadcrumbs
              breadcrumbs={breadcrumbs}
              onBreadcrumbClick={handleBreadcrumbClick}
            />
          </div>
          {cars.map((car) => (
            <div
              key={car.id}
              className="car-card"
              onClick={() => handleCarClick(car)}
            >
              <img
                src={require(`../../assets/photo/Cars/${car.path}`)}
                alt={car.Model}
                className="car-image"
              />
              <div className="car-details">
                <h2>{car.Model}</h2>

                <p>ผู้โดยสาร: {car.passengers}</p>
                <p>สัมภาระ: {car.luggages}</p>
                <p>ราคา: เริ่มต้นที่ {car.price} ¥</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Car;
