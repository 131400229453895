import React from "react";
import "./ImageEnlargement.css";
import { useParams } from "react-router-dom";

const ImageEnlargement = ({ selectedImage, onClose }) => {
  const { id } = useParams();

  return (
    <div className="image-enlargement-overlay" onClick={onClose}>
      <div className="image-enlargement-content">
        <img
          src={require(`../../assets/photo/Rooms/${id}/${selectedImage}`)}
          alt="enlarged"
        />
        <button onClick={onClose}>ปิด</button>
      </div>
    </div>
  );
};

export default ImageEnlargement;
