import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as JapanIcon } from "../../../src/assets/photo/Icon/japanese.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCarAlt,
  faEarthAmericas,
  faHome,
  faHotel,
} from "@fortawesome/free-solid-svg-icons";
import { FaPassport } from "react-icons/fa";

const Navbar = () => {
  return (
    <div
      className="navbar navbar-expand-lg navbar-light bg-light
      shadow-sm sticky-top"
    >
      <nav className="navbar">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse fw-light" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="/">
                <FontAwesomeIcon className="nav-icon" icon={faHome} />
                หน้าหลัก
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/privatetour">
                <FaPassport className="nav-icon" />
                ทัวร์ส่วนตัว
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/japantour">
                <JapanIcon className="nav-icon" />
                ทัวร์ญี่ปุ่น
              </a>
            </li>{" "}
            <li className="nav-item">
              <a className="nav-link" href="/tour">
                <FontAwesomeIcon className="nav-icon" icon={faEarthAmericas} />
                ทัวร์อื่น ๆ
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/carrental">
                <FontAwesomeIcon className="nav-icon" icon={faCarAlt} />
                เช่ารถ
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/room">
                <FontAwesomeIcon className="nav-icon" icon={faHotel} />
                บ้านพัก
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
