import React from "react";
import "./common.css";
import { FaPhone, FaFacebookF } from "react-icons/fa";
import Line from "../../assets/photo/Logo/line.png";

const Footer = () => {
  return (
    <div className="page-wrapper">
      <footer className="footer-container">
        <div className="footer-content">
          <div className="footer-contact">
            <h4>Contact Us</h4>
            <p>อีเมลล์: Thanyaporntours@gmail.com</p>
            <p>โทรศัพท์ (TH): +66 91-863-9669</p>
            <p>
              ที่อยู่: 528/146 หมู่ 22 ตำบลบ้านเป็ด อำเภอเมืองขอนแก่น
              จังหวัดขอนแก่น 40000
            </p>
            <p>ที่อยู่: 1-15-3 Hanaokamachi, Takayama, Gifu, 506-0009 Japan</p>
          </div>
          <div className="footer-social">
            <h4>Follow Us</h4>
            <div className="footer-social-icons">
              <a
                href="https://www.facebook.com/thanyaporntours"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF />
              </a>
              <a href="tel:+66918639669">
                <FaPhone />
                <p className="text-icon">TH</p>
              </a>
              <a href="tel:+819044024607">
                <FaPhone />
                <p className="text-icon">JP</p>
              </a>
              <a
                href="https://line.me/R/ti/p/@thanyaporntour?from=page&searchId=thanyaporntour"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="line" src={Line} alt="Logo" />
              </a>
            </div>
          </div>
          <div className="footer-links">
            <h4>Quick Links</h4>
            <ul>
              <li>
                <a href="/">หน้าหลัก</a>
              </li>
              <li>
                <a href="/japantour">ทัวร์ญี่ปุ่น</a>
              </li>
              <li>
                <a href="/room">ที่พัก</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>
            &copy; {new Date().getFullYear()} Thanyaporn Tours. All rights
            reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
