import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getYear, getMonth } from "date-fns";
import { formatDateThai } from "./formatDateThai";
import "./customDatePicker.css";

function CustomDatePicker({
  startDateProp,
  onChangeStart,
  onChangeEnd,
  placeholderText,
}) {
  const [startDate, setStartDate] = useState(startDateProp || new Date());
  const currentYear = getYear(new Date());
  const years = range(currentYear, currentYear + 3, 1);

  useEffect(() => {
    setStartDate(startDateProp || new Date());
  }, [startDateProp]);

  function range(start, end, step = 1) {
    const len = Math.floor((end - start) / step) + 1;
    return Array(len)
      .fill()
      .map((_, idx) => start + idx * step);
  }

  const months = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  return (
    <DatePicker
      renderCustomHeader={({ date, changeYear, changeMonth }) => (
        <div
          className="custom-datepicker-header"
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      )}
      selected={startDate}
      onChange={(date) => {
        if (onChangeStart) {
          onChangeStart(date);
        } else if (onChangeEnd) {
          onChangeEnd(date);
        }
        setStartDate(date);
      }}
      dateFormat={formatDateThai(startDate)}
      minDate={new Date()}
      placeholderText={placeholderText}
    />
  );
}

export default CustomDatePicker;
