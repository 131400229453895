import React, { useState } from "react";
import { checkAdminRole } from "../admin/authUtils";
import { useNavigate } from "react-router-dom";

const AdminLoginStatus = () => {
  const navigate = useNavigate();
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);

  const handleClick = () => {
    navigate("/adminpanel");
  };

  React.useEffect(() => {
    const isAdmin = checkAdminRole();
    setIsAdminLoggedIn(isAdmin);
  }, []);

  return (
    <div>
      <div className="admin-login-status-container">
        <div className="admin-login-status">
          {isAdminLoggedIn ? <p>Login as admin</p> : <p>Login as user</p>}
          <button className="button-admin-panel" onClick={handleClick}>
            Admin Panel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginStatus;
