import React from "react";
import Login from "./login";
import { getToken, setToken, removeToken } from "./auth";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { checkAdminRole } from "./authUtils";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import "./admin.css";

const AdminPanel = () => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      setLoggedIn(true);
    }
    setIsAdmin(checkAdminRole());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const token = getToken();
      if (token) {
        try {
          const decodedToken = jwt_decode(token);
          const currentTime = Date.now() / 1000;

          if (decodedToken.exp > currentTime) {
            handleLogout();
          }
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      }
    }, 10000000);

    return () => clearInterval(interval);
  }, []);

  const handleLogin = (token) => {
    setToken(token);
    setLoggedIn(true);
    localStorage.setItem("isAdminLoggedIn", "true");
    localStorage.setItem("authToken", token);
  };

  const handleLogout = () => {
    removeToken();
    setLoggedIn(false);
    localStorage.removeItem("isAdminLoggedIn");
    localStorage.removeItem("authToken");
    navigate("/");
  };

  if (!loggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div>
      <div className="admin-panel-container">
        <div className="admin-panel">
          <h1>Admin Panel</h1>

          <div className="actions">
            <Link
              to="/adminpanel/jp/addtour"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <button>เพิ่มทัวร์ญี่ปุ่น</button>
            </Link>
            <Link
              to="/adminpanel/fb/addtour"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <button>เพิ่มทัวร์อื่น ๆ</button>
            </Link>
            <Link
              to="/adminpanel/jp/deletetour"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <button>ลบทัวร์ญี่ปุ่น</button>
            </Link>
            <Link
              to="/adminpanel/fb/deletetour"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <button>ลบทัวร์อื่น ๆ</button>
            </Link>
            <Link
              to="/adminpanel/datalist"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <button>ฟอร์มลูกค้า</button>
            </Link>
          </div>
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};
export default AdminPanel;
