const cars = [
  {
    id: "Alphard",
    Model: "รถอเนกประสงค์ (Alphard)",
    passengers: "5",
    luggages: "4",
    price: "75,000",
    path: "alphard.png",
  },
  {
    id: "Hiace",
    Model: "รถตู้ (Hiace Grand Cabin)",
    passengers: "9",
    luggages: "9",
    price: "75,000",
    path: "hiace.png",
  },

  {
    id: "Coaster",
    Model: "รถไมโครบัส (Coaster)",
    passengers: "15 - 20",
    luggages: "14",
    price: "130,000",
    path: "coaster.png",
  },
  {
    id: "Bus",
    Model: "รถบัส (Bus)",
    passengers: "30 - 45",
    luggages: "30 - 40",
    price: "170,000",
    path: "bus.png",
  },
];

export default cars;
