import Navbar from "./components/common/navbar";
import Header from "./components/common/header";
import Home from "./components/homepage/home";
import JapanTour from "./components/tour/japan";
import FootballTour from "./components/tour/football";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import DataList from "./components/admin/dataList";
import "./css/global.css";
import JapanDetail from "./components/tour/japanDetail";
import FootballDetail from "./components/tour/footballDetail";
import JapanFormTour from "./components/tour/jpFormTour";
import FootballFormTour from "./components/tour/fbFormTour";
import Car from "./components/carrentel/car";
import CarForm from "./components/carrentel/carForm";
import Social from "./components/common/social";
import Footer from "./components/common/footer";
import Room from "./components/hotel/room";
import RoomForm from "./components/hotel/roomform";
import AdminPanel from "./components/admin/adminPanel";
import FbDeleteTour from "./components/admin/fbDeleteTour";
import JpDeleteTour from "./components/admin/jpDeleteTour";
import FbAddTour from "./components/admin/fbAddTour";
import JpAddTour from "./components/admin/JpAddTour";
import ErrorBoundary from "./errorBoundary";
import DetailPage from "./components/admin/detailPage";
import PrivateTour from "./components/tour/privateTour";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/carrental" element={<Car />}></Route>
        <Route path="/Japantour" element={<JapanTour />}></Route>
        <Route path="/tour" element={<FootballTour />}></Route>
        <Route path="/adminpanel/datalist" element={<DataList />}></Route>
        <Route path="/detail/:id" element={<DetailPage />}></Route>
        <Route path="/japantour/:id" element={<JapanDetail />}></Route>
        <Route path="/tour/:id" element={<FootballDetail />}></Route>
        <Route path="/jpform/:id" element={<JapanFormTour />}></Route>
        <Route path="/fbform/:id" element={<FootballFormTour />}></Route>
        <Route path="/carform/:id" element={<CarForm />}></Route>
        <Route path="/room" element={<Room />}></Route>
        <Route path="/roomform/:id" element={<RoomForm />}></Route>
        <Route path="/adminpanel" element={<AdminPanel />}></Route>
        <Route path="/adminpanel/fb/deletetour" element={<FbDeleteTour />} />
        <Route path="/adminpanel/jp/deletetour" element={<JpDeleteTour />} />
        <Route path="/adminpanel/fb/addtour" element={<FbAddTour />} />
        <Route path="/adminpanel/jp/addtour" element={<JpAddTour />} />
        <Route path="/privatetour" element={<PrivateTour />} />
      </Routes>

      <Social />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
