import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import HotelImage from "../../../src/assets/photo/Rooms/Hotel/Cover/Cover.jpg";
import JapanTour from "../../../src/assets/photo/Japan/Asakusa.jpg";
import CarRent from "../../../src/assets/photo/Cars/alphard.png";
import SearchBar from "../common/searchBar";
import { useNavigate } from "react-router-dom";
import ImageCarousel from "../common/imageCarousel";
import "./home.css";
import "bootstrap/dist/css/bootstrap.min.css";

const cities = [
  { city: "โตเกียว", weather: "Tokyo" },
  { city: "โอซาก้า", weather: "Osaka" },
  { city: "ฮอกไกโด", weather: "Hokkaido" },
  { city: "ฟุกุโอกะ", weather: "Fukuoka" },
];

const Home = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [impression, setImpression] = useState("");
  const [bangkokTime, setBangkokTime] = useState("");
  const [japanTime, setJapanTime] = useState("");
  const [weatherData, setWeatherData] = useState(null);
  const [currentCityIndex, setCurrentCityIndex] = useState(0);
  const url = process.env.REACT_APP_API_URL;
  const weatherTranslations = {
    "Clear sky or clear": "ฟ้าใส",
    Clear: "ฟ้าใส",
    "Partly cloudy": "มีเมฆบางส่วน",
    Cloudy: "มีเมฆมาก",
    "Fog or mist": "หมอกหรือหมอกควัน",
    Drizzle: "ฝนฤดูกาล",
    "Light rain": "ฝนเบา",
    "Moderate rain": "ฝนปานกลาง",
    "Heavy rain": "ฝนตกหนัก",
    "Light snow": "หิมะเบา",
    "Moderate snow": "หิมะปานกลาง",
    "Heavy snow": "หิมะตกหนัก",
    "Thunderstorm or lightning": "ฝนฟ้าคะนองหรือฟ้าระหว่างการฟ้าคะนอง",
    Hail: "ลูกเห็บ",
    Showers: "ฝนตก",
    "Scattered clouds": "มีเมฆแบบกระจาย",
    "Broken clouds": "มีเมฆแตกเป็นชิ้นๆ",
    "Rain and snow mix": "ฝนและหิมะผสม",
    Clear: "ท้องฟ้าปรอดโปร่ง",
    Sunny: "แดดออก",
    Overcast: "มืดครึ้ม",
    Mist: "หมอกหรือหมอกควัน",
    "Patchy rain possible": "ฝนฝนเล็กน้อยเป็นไปได้",
    "Patchy snow possible": "หิมะเล็กน้อยเป็นไปได้",
    "Patchy sleet possible": "สับสนเล็กน้อยเป็นไปได้",
    "Patchy freezing drizzle possible": "น้ำประสานเล็กน้อยเป็นไปได้",
    "Light rain shower": "ฝนตกปรอย",
    "Patchy light rain with thunder": "ฝนตกปรอย ๆ และ ฟ้าร้อง",
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(updateTimes, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response1 = await axios.get(`${url}japantour`);
      setData(response1.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchWeatherData = async (cityName) => {
    const updatedWeatherData = [];
    const city = cities[currentCityIndex];

    try {
      const response = await axios.get(
        `https://api.weatherapi.com/v1/current.json?key=${process.env.REACT_APP_API_WEATHER}&q=${city.weather}&aqi=no`
      );

      const weather = response.data.current;
      const conditionText = weather.condition
        ? weather.condition.text
        : "Unknown";
      const translatedWeather = weatherTranslations[conditionText];

      return {
        city: cityName,
        temp: weather.temp_c,
        condition: translatedWeather,
        icon: weather.condition.icon,
      };
    } catch (error) {
      console.error(`Error fetching weather data for ${city.city}:`, error);
    }

    return updatedWeatherData;
  };

  useEffect(() => {
    const fetchData = async () => {
      const currentCity = cities[currentCityIndex];
      const data = await fetchWeatherData(currentCity.weather);

      if (data) {
        setWeatherData([data]);
      }
    };

    fetchData();

    const interval = setInterval(async () => {
      setCurrentCityIndex((prevIndex) => (prevIndex + 1) % cities.length);
      const currentCity = cities[currentCityIndex];
      const weatherData = await fetchWeatherData(currentCity.weather);

      if (weatherData) {
        setWeatherData([weatherData]);
      }
    }, 120000);

    return () => {
      clearInterval(interval);
    };
  }, [currentCityIndex]);

  const calculateTime = (offset) => {
    const now = new Date();
    const utcTime = now.getTime() + now.getTimezoneOffset() * 60000;
    const localTime = new Date(utcTime + offset * 3600000);
    const hours = localTime.getHours();
    const minutes = localTime.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return formattedTime;
  };

  const updateTimes = () => {
    const bangkokTime = calculateTime(7);
    const japanTime = calculateTime(9);

    setBangkokTime(bangkokTime);
    setJapanTime(japanTime);
  };

  const handleSearch = (searchData) => {
    const { tourType, airline, city, keywords } = searchData;

    const searchParams = new URLSearchParams(searchData);
    let searchUrl = "";

    if (tourType === "japantour") {
      searchUrl = `/japantour?${searchParams.toString()}`;
    } else if (tourType === "footballtour") {
      searchUrl = `/tour?${searchParams.toString()}`;
    }

    navigate(searchUrl);
  };

  return (
    <div>
      <div className="container-home">
        <Link to="/japantour" style={{ textDecoration: "none" }}>
          <div className="home-cover">
            <img
              className="fade-in"
              src="https://storage.googleapis.com/thanyaporn_tour/Homepage/HomeCover.png"
            ></img>
          </div>
        </Link>
        <div className="header-container">
          <div className="search-container wipe-in-left">
            <SearchBar onSearch={handleSearch} />
          </div>
          <div className="time-weather-container wipe-in-right">
            <div className="time-zone">
              <div className="time-zone-box">
                <h3 className="zone-title">โตเกียว</h3>
                <p className="zone-time">{japanTime}</p>
              </div>
              <div className="time-zone-box">
                <h3 className="zone-title">กรุงเทพ</h3>
                <p className="zone-time">{bangkokTime}</p>
              </div>
            </div>
            <div className="weather-box">
              <div className="weather-city">
                <h2>{cities[currentCityIndex].city}</h2>
                <img
                  className="flag"
                  src={require("../../assets/photo/Logo/japanese.png")}
                  alt="Weather Icon"
                  width="60"
                  height="44"
                />
              </div>
              <div className="weather-detail">
                {weatherData && weatherData[0] ? (
                  <>
                    <p className="temp">{weatherData[0].temp}°C</p>
                    <p>{weatherData[0].condition}</p>
                  </>
                ) : (
                  <p>Loading weather data...</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-deck-container  card-animation">
          <div className="card-deck">
            <div class="card card-home">
              <Link
                to={`/japantour`}
                style={{ textDecoration: "none", color: "black" }}
              >
                <img
                  className="card-img-top"
                  src={JapanTour}
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h5 className="card-title">ทัวร์ญี่ปุ่น</h5>
                  <p className="card-text">
                    รับจัดกรุ๊ปทัวร์ญี่ปุ่นคุณภาพ
                    พาท่องเที่ยวสถานที่ยอดนิยมทุกแบบทุกเส้นทางทั่วญี่ปุ่น
                    ค้นหาโรงแรมที่พักและร้านอาหารนิยม
                  </p>
                </div>
              </Link>
            </div>

            <div className="card card-home">
              <Link
                to={`/room`}
                style={{ textDecoration: "none", color: "black" }}
              >
                <img
                  class="card-img-top"
                  src={HotelImage}
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title">Thanyaporn Hotel</h5>
                  <p class="card-text">
                    Thanyaporn Hotel ตั้งอยู่ในใจกลางเมือง Takayama
                    ที่พักให้บริการห้องพักพร้อมอินเทอร์เน็ตไร้สาย
                    เครื่องปรับอากาศ และห้องอาหาร
                    ที่พักมีพื้นที่จอดรถส่วนตัวในสถานที่
                  </p>
                </div>
              </Link>
            </div>
            <div class="card card-home">
              <Link
                to={`/carrental`}
                style={{ textDecoration: "none", color: "black" }}
              >
                <img class="card-img-top" src={CarRent} alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title">รถพร้อมคนขับ</h5>
                  <p class="card-text">
                    รถยนต์ส่วนตัวพร้อมคนขับมืออาชีพ ตามโปรแกรมที่ลูกค้าต้องการ
                    สามารถปรับเปลี่ยนได้ตามความต้องการลูกค้า บริการรวมค่ารถ
                    คนขับ น้ำมัน ค่าผ่านทางและที่จอดรถ
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>{" "}
        <div className="more-tour card-animation">
          <div className="home-btn-container">
            <Link to="/japantour?city=Tokyo" style={{ textDecoration: "none" }}>
              <div className="btn-with-image">
                <div className="img-container">
                  <img
                    src="https://storage.googleapis.com/thanyaporn_tour/Homepage/Tokyo.jpg"
                    alt="Tour Image 1"
                    className="rounded-image"
                  />
                  <p>ทัวร์โตเกียว</p>
                </div>
              </div>
            </Link>
            <Link to="/japantour?city=Osaka" style={{ textDecoration: "none" }}>
              <div className="btn-with-image">
                <div className="img-container">
                  <img
                    src="https://storage.googleapis.com/thanyaporn_tour/Homepage/Osaka.jpg"
                    alt="Tour Image 2"
                    className="rounded-image"
                  />{" "}
                  <p>ทัวร์โอซาก้า</p>
                </div>
              </div>
            </Link>
            <Link
              to="/japantour?city=Sapporo"
              style={{ textDecoration: "none" }}
            >
              <div className="btn-with-image">
                <div className="img-container">
                  <img
                    src="https://storage.googleapis.com/thanyaporn_tour/Homepage/Sapporo.jpg"
                    alt="Car Rental Image"
                    className="rounded-image"
                  />{" "}
                  <p>ทัวร์ซัปโปโร</p>
                </div>
              </div>
            </Link>
            <Link
              to="/japantour?city=Fukuoka"
              style={{ textDecoration: "none" }}
            >
              <div className="btn-with-image">
                <div className="img-container">
                  <img
                    src="https://storage.googleapis.com/thanyaporn_tour/Homepage/Fukuoka.jpg"
                    alt="Room Image"
                    className="rounded-image"
                  />{" "}
                  <p>ทัวร์ฟุกุโอกะ</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <ImageCarousel />
      </div>
    </div>
  );
};

export default Home;
