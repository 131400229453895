const StarSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      fill="#FFB500"
      d="M12 18.301l5.851 3.532-1.567-6.729 5.234-4.539-6.891-.594L12 2l-2.628 7.972-6.89.594 5.234 4.539-1.567 6.729z"
    />
  </svg>
);

export default StarSVG;
