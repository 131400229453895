import React, { useEffect, useState } from "react";
import cars from "../common/car";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Breadcrumbs from "../common/breadCrumbs";
import Swal from "sweetalert2";
import CustomDatePicker from "../common/customDatePicker";
import { formatDateThai } from "../../components/common/formatDateThai";
import "./carForm.css";

const CarForm = () => {
  const { id } = useParams();
  const car = cars.find((car) => car.id === id);
  const navigate = useNavigate();
  const [exchangeRate, setExchangeRate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [thbPrice, setThbPrice] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const url = process.env.REACT_APP_API_URL;
  const cities = [
    { label: "Tokyo", value: "Tokyo" },
    { label: "Osaka", value: "Osaka" },
    { label: "Sapporo", value: "Sapporo" },
    { label: "Nagoya", value: "Nagoya" },
    { label: "Fukuoka", value: "Fukuoka" },
  ];

  const [breadcrumbs, setBreadcrumbs] = useState([
    { label: "หน้าหลัก", path: "/" },
    { label: "บริการรถ", path: "/carrental" },
    { label: "กรอกฟอร์ม", path: `/carform/${car.id}` },
  ]);

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    tel: "",
    moreDetail: "",
    line: "",
    destination: cities[0].value,
    startingCity: cities[0].value,
    maxGuests: 1,
    maxDays: 1,
    start: new Date(),
    end: new Date(),
    tour: ``,
  });

  const numberOfDays =
    startDate && endDate
      ? Math.abs(Math.ceil((endDate - startDate) / (24 * 60 * 60 * 1000)))
      : null;

  useEffect(() => {
    const fetchCurrencyConversion = async () => {
      try {
        const response = await axios.get(
          "https://api.exchangerate-api.com/v4/latest/JPY"
        );
        const jpyToThbRate = response.data.rates.THB;
        const rate = response.data.rates.THB;
        const priceInJPY = parseFloat(car.price.replace(/,/g, ""));
        const priceInTHB = priceInJPY * jpyToThbRate;
        setThbPrice(priceInTHB.toLocaleString());
        setExchangeRate(rate);
      } catch (error) {
        console.error("Error fetching currency conversion:", error);
      }
    };

    fetchCurrencyConversion();
  }, [car.price]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setFormData({ ...formData, start: date });
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setFormData({ ...formData, end: date });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const guestOptions = Array.from(
    { length: car.passengers },
    (_, index) => index + 1
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleSubmitModal = async (e) => {
    e.preventDefault();
    const formDataToSend = {
      ...formData,
      tour: `${car.Model}`,
    };
    try {
      await axios.post(`${url}formdetail`, formDataToSend);
      setShowModal(false);

      Swal.fire({
        icon: "success",
        title: "เรียบร้อย!!",
        text: "เราจะทำการติดต่อกลับให้เร็วที่สุด",
        customClass: {
          popup: "custom-sweetalert",
          confirmButton: "custom-confirm-button",
        },
        buttonsStyling: true,
        confirmButtonText: "ตกลง",
      });
    } catch (error) {
      console.error("Error submitting form data:", error);

      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: "เกิดข้อผิดพลาดในการส่งข้อมูลแบบฟอร์ม กรุณาลองอีกครั้ง",
        customClass: {
          popup: "custom-sweetalert",
          confirmButton: "custom-confirm-button",
        },
        buttonsStyling: true,
        confirmButtonText: "ตกลง",
      });
    }
    setFormData({
      name: "",
      surname: "",
      tel: "",
      email: "",
      line: "",
      moreDetail: "",
      destination: cities[0].value,
      startingCity: cities[0].value,
      start: new Date(),
      end: new Date(),
      tour: ``,
    });
  };

  const handleBreadcrumbClick = (path) => {
    navigate(path);
  };

  return (
    <div>
      <div className="container-main">
        <div className="container-detail">
          <div className="car-header">
            <Breadcrumbs
              breadcrumbs={breadcrumbs}
              onBreadcrumbClick={handleBreadcrumbClick}
            />
          </div>
          <div className="sub-container-main">
            <div className="sub-container-content">
              <div className="form-sub-container">
                <div className="car-form-container-title">
                  <h2>{car.Model}</h2>
                </div>
                <div className="form-sub-container-detail">
                  <div className="car-form-content-container">
                    <img
                      className="car-img"
                      src={require(`../../assets/photo/Cars/${car.path}`)}
                      alt={car.Model}
                    />
                    <ul className="car-form-content-list">
                      <div>
                        <span className="label-detail">
                          จำนวนผู้โดยสารที่แนะนำ
                        </span>

                        <p>{car.passengers} คน</p>
                      </div>
                      <div>
                        <span className="label-detail">สัมภาระ</span>

                        <p>{car.luggages} ใบ</p>
                      </div>
                      <div>
                        <span className="label-detail">ราคาโดยประมาณ</span>
                        <p>{thbPrice} บาท/วัน</p>
                      </div>
                      <div>
                        <span className="label-detail">ค่าเงินโดยประมาณ</span>
                        <p>1 บาท = {exchangeRate} เยน</p>
                      </div>
                    </ul>
                  </div>
                  <div className="container-form-detail">
                    <div className="car-head-detail">
                      <h3>รายละเอียดสำหรับการติดต่อกลับ</h3>
                    </div>
                    <form className="form" onSubmit={handleSubmit}>
                      <div className="form-container">
                        <ul>
                          <div className="form-group">
                            <label htmlFor="startingCity">จุดเริ่มต้น</label>
                            <div className="cursor-pointer">
                              <select
                                className="form-control"
                                id="startingCity"
                                name="startingCity"
                                value={formData.startingCity}
                                onChange={handleChange}
                              >
                                {cities.map((city) => (
                                  <option key={city.value} value={city.value}>
                                    {city.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="destination">ปลายทาง</label>
                            <div className="cursor-pointer">
                              <select
                                className="form-control"
                                id="destination"
                                name="destination"
                                value={formData.destination}
                                onChange={handleChange}
                              >
                                {cities.map((city) => (
                                  <option key={city.value} value={city.value}>
                                    {city.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="maxGuests">จำนวนผู้โดยสาร</label>
                            <div className="cursor-pointer">
                              <select
                                className="form-control"
                                id="maxGuests"
                                name="maxGuests"
                                value={formData.maxGuests}
                                onChange={handleChange}
                              >
                                {guestOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </ul>
                        <ul>
                          <div className="form-group">
                            <label htmlFor="dateIn">วันเข้าพัก</label>
                            <CustomDatePicker
                              name="start"
                              className="form-control date-input"
                              startDateProp={startDate}
                              onChangeStart={handleStartDateChange}
                              dateFormat="yyyy-MM-dd"
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="dateOut">วันเช็คเอาท์</label>
                            <CustomDatePicker
                              name="end"
                              className="form-control date-input"
                              startDateProp={endDate}
                              onChangeEnd={handleEndDateChange}
                              dateFormat="yyyy-MM-dd"
                            />
                          </div>

                          <div className="form-group">
                            <label>จำนวนวัน</label>{" "}
                            <input
                              value={numberOfDays || 0}
                              className="form-control custom-input"
                              readOnly
                            />
                          </div>
                        </ul>
                        <ul>
                          <div className="form-group">
                            <label htmlFor="name">ชื่อ</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              required
                              onInvalid={(e) =>
                                e.target.setCustomValidity("กรุณากรอกชื่อ")
                              }
                              onInput={(e) => e.target.setCustomValidity("")}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="surname">นามสกุล</label>
                            <input
                              type="text"
                              className="form-control"
                              id="surname"
                              name="surname"
                              value={formData.surname}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="tel">เบอร์โทรศัพท์</label>
                            <input
                              type="text"
                              className="form-control"
                              id="tel"
                              name="tel"
                              value={formData.tel}
                              onChange={handleChange}
                              required
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  "กรุณากรอกเบอร์โทรศัพท์"
                                )
                              }
                              onInput={(e) => e.target.setCustomValidity("")}
                            />
                          </div>
                        </ul>
                        <ul>
                          <div className="form-group">
                            <label htmlFor="line">ไอดีไลน์</label>
                            <input
                              type="text"
                              className="form-control"
                              id="line"
                              name="line"
                              value={formData.line}
                              onChange={handleChange}
                              required
                              onInvalid={(e) =>
                                e.target.setCustomValidity("กรุณากรอกไอดีไลน์")
                              }
                              onInput={(e) => e.target.setCustomValidity("")}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="email">อีเมลล์</label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="tour">แพคเกจ</label>
                            <input
                              type="text"
                              className="form-control custom-input"
                              id="tour"
                              name="tour"
                              defaultValue={car.Model}
                              readOnly
                            />
                          </div>
                        </ul>
                        <ul>
                          <div className="form-group">
                            <label htmlFor="moreDetail">ข้อมูลเพิ่มเติม</label>
                            <textarea
                              type="text"
                              className="form-control"
                              id="moreDetail"
                              name="moreDetail"
                              value={formData.moreDetail}
                              onChange={handleChange}
                              rows="5"
                            ></textarea>
                          </div>
                        </ul>
                        <div className="submit-button-container">
                          <button className="btn-detail">ส่งข้อมูล</button>
                        </div>
                      </div>
                    </form>
                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                      <Modal.Header closeButton>
                        <Modal.Title>ข้อมูลการจอง</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>
                          <span className="label-data">ชื่อ: </span>
                          {formData.name}
                        </p>
                        <p>
                          <span className="label-data">นามสกุล: </span>
                          {formData.surname}
                        </p>

                        <p>
                          <span className="label-data">
                            จุดเริ่มต้น - ปลายทาง:{" "}
                          </span>
                          {formData.startingCity} - {formData.destination}
                        </p>

                        <p>
                          <span className="label-data">วันเริ่มต้น: </span>
                          {formData.start && formatDateThai(formData.start)}
                        </p>

                        <p>
                          <span className="label-data">วันสุดท้าย: </span>

                          {formData.end && formatDateThai(formData.end)}
                        </p>
                        <p>
                          <span className="label-data">จำนวนผู้โดยสาร: </span>
                          {formData.maxGuests}
                        </p>
                        <p>
                          <span className="label-data">ไอดีไลน์: </span>
                          {formData.line}
                        </p>
                        <p>
                          <span className="label-data">เบอร์โทรศัพท์: </span>
                          {formData.tel}
                        </p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          className="btn-detail-modal-cancel"
                          variant="secondary"
                          onClick={() => setShowModal(false)}
                        >
                          ยกเลิก
                        </Button>
                        <Button
                          className="btn-detail-modal"
                          variant="primary"
                          onClick={handleSubmitModal}
                        >
                          ยืนยัน
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showModal && <Modal />}
      </div>
    </div>
  );
};

export default CarForm;
