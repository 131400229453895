import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { checkAdminRole } from "./authUtils";
import { Button, Modal } from "react-bootstrap";

const DetailPage = () => {
  const { id } = useParams();
  const [detailData, setDetailData] = useState(null);
  const navigate = useNavigate();
  const isAdmin = checkAdminRole();
  const url = process.env.REACT_APP_API_URL;
  const [showModal, setShowModal] = useState(false);

  const fetchDetailData = async () => {
    try {
      setShowModal(false);
      const response = await axios.get(`${url}formdetail/${id}`);
      setDetailData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitModal = async (e) => {
    try {
      await axios.delete(`${url}formdetail/${id}`);
    } catch (error) {
      console.log("Error deleting form", error);
    }
    navigate(`/adminpanel/datalist`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  useEffect(() => {
    fetchDetailData();
  }, [id]);

  return (
    <div className="detail-page-container">
      {isAdmin ? (
        <>
          {detailData ? (
            <div className="detail-content">
              <div className="data-details">
                {detailData.name && (
                  <div className="detail-row">
                    <p className="label">ชื่อ</p>
                    <p className="data">{detailData.name}</p>
                  </div>
                )}
                {detailData.surname && (
                  <div className="detail-row">
                    <p className="label">นามสกุล</p>
                    <p className="data">{detailData.surname}</p>
                  </div>
                )}
                {detailData.tel && (
                  <div className="detail-row">
                    <p className="label">เบอร์โทรศัพท์</p>
                    <p className="data">{detailData.tel}</p>
                  </div>
                )}
                {detailData.moreDetail && (
                  <div className="detail-row">
                    <p className="label">ข้อมูล</p>
                    <p className="data">{detailData.moreDetail}</p>
                  </div>
                )}
                {detailData.email && (
                  <div className="detail-row">
                    <p className="label">อีเมลล์</p>
                    <p className="data">{detailData.email}</p>
                  </div>
                )}
                {detailData.guests && (
                  <div className="detail-row">
                    <p className="label">จำนวน</p>
                    <p className="data">{detailData.guests} คน,วัน</p>
                  </div>
                )}
                {detailData.line && (
                  <div className="detail-row">
                    <p className="label">ไอดีไลน์</p>
                    <p className="data">{detailData.line}</p>
                  </div>
                )}
                {detailData.tour && (
                  <div className="detail-row">
                    <p className="label">ประเภททัวร์</p>
                    <p className="data">{detailData.tour}</p>
                  </div>
                )}
                {detailData.price && (
                  <div className="detail-row">
                    <p className="label">ราคา</p>
                    <p className="data">{detailData.price}</p>
                  </div>
                )}
                {detailData.start && (
                  <div className="detail-row">
                    <p className="label">วันเริ่มต้น</p>
                    <p className="data">
                      {detailData.start &&
                        new Intl.DateTimeFormat("th-TH", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }).format(new Date(detailData.start))}
                    </p>
                  </div>
                )}
                {detailData.end && (
                  <div className="detail-row">
                    <p className="label">วันสุดท้าย</p>
                    <p className="data">
                      {detailData.end &&
                        new Intl.DateTimeFormat("th-TH", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }).format(new Date(detailData.end))}
                    </p>
                  </div>
                )}
              </div>
              <button onClick={handleSubmit} className="ad-delete-button">
                ลบฟอร์มนี้
              </button>{" "}
              <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>ยืนยันการลบ</Modal.Title>
                </Modal.Header>
                <Modal.Body>คุณแน่ใจหรือไม่ที่ต้องการลบฟอร์มนี้?</Modal.Body>
                <Modal.Footer>
                  <Button
                    className="btn-detail-modal-cancel"
                    variant="secondary"
                    onClick={() => setShowModal(false)}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    className="btn-detail-modal"
                    variant="primary"
                    onClick={handleSubmitModal}
                  >
                    ยืนยัน
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </>
      ) : (
        <p>You are not authorized to access this page.</p>
      )}
    </div>
  );
};

export default DetailPage;
