import React, { useState } from "react";
import axios from "axios";
import { checkAdminRole } from "./authUtils";
import "./addtour.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";

const FbAddTour = () => {
  const isAdmin = checkAdminRole();
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [highlight, setHighlight] = useState("");
  const url = process.env.REACT_APP_API_URL;
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    day: "",
    price: "",
    night: "",
    highlight: "",
    path: "",
    logo: "",
    meal: "",
    days: [
      {
        morning: "",
        midday: "",
        evening: "",
      },
    ],
    pathPDF: "",
  });

  const [dayEditorStates, setDayEditorStates] = useState(
    formData.days.map((day) => ({
      morning: "",
      midday: "",
      evening: "",
    }))
  );

  const [dayEditors, setDayEditors] = useState(
    Array.from({ length: formData.day }, () => "")
  );

  const handleHighlightChange = (value) => {
    setHighlight(value);
  };

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handlePDFChange = (e) => {
    setSelectedPDF(e.target.files[0]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAdd = async () => {
    try {
      if (!selectedImage) {
        Swal.fire(
          "Error",
          "Please select both an image and a PDF to upload.",
          "error"
        );
        return;
      }

      const imageFormData = new FormData();
      imageFormData.append("image", selectedImage);
      imageFormData.append("page", "Footballtour");

      Swal.fire({
        title: "Uploading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const imageUploadResponse = await axios.post(
        `${url}uploads`,
        imageFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (imageUploadResponse.status === 201) {
        setUploadStatus("Upload successful");

        const tourData = {
          ...formData,
          highlight: highlight,
          days: dayEditors,
          path: imageUploadResponse.data.path,
        };

        if (selectedPDF) {
          const pdfFormData = new FormData();
          pdfFormData.append("pdf", selectedPDF);
          pdfFormData.append("page", "FootballtourPDF");

          const pdfUploadResponse = await axios.post(
            `${url}uploads-pdf`,
            pdfFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (pdfUploadResponse.status === 201) {
            tourData.pathPDF = pdfUploadResponse.data.path;
          }
        }

        const daysArray = [];

        formData.days.forEach((dayData, index) => {
          daysArray.push(dayData);
        });

        tourData.days = daysArray;

        const tourResponse = await axios.post(`${url}footballtour`, tourData);
        console.log("Tour Response:", tourResponse);
        Swal.close();

        if (tourResponse.status === 200) {
          Swal.fire("Success", "Tour added successfully", "success");
          setFormData({
            title: "",
            day: "",
            price: 0,
            night: "",
            highlight: "",
            path: "",
            logo: "",
            meal: "",
            days: [
              {
                morning: "",
                midday: "",
                evening: "",
              },
            ],
          });
        } else {
          console.log("Tour addition failed. Response:", tourResponse);
          Swal.fire("Error", "Failed to add tour.", "error");
        }
      } else {
        setUploadStatus("Failed to upload files.");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setUploadStatus("An error occurred while uploading the files.");
    }
  };

  const handleDayEditorChange = (value, dayIndex, timeOfDay) => {
    const updatedDayEditorStates = [...dayEditorStates];
    updatedDayEditorStates[dayIndex][timeOfDay] = value;
    setDayEditorStates(updatedDayEditorStates);

    const updatedFormData = { ...formData };
    updatedFormData.days[dayIndex][timeOfDay] = value;
    setFormData(updatedFormData);
  };

  const handleDayChange = (e) => {
    const numberOfDays = parseInt(e.target.value, 15);
    const newDays = Array.from({ length: numberOfDays }, () => ({
      morning: "",
      midday: "",
      evening: "",
    }));
    setFormData({
      ...formData,
      day: numberOfDays,
      days: newDays,
    });
    setDayEditors(Array.from({ length: numberOfDays }, () => ""));
    setDayEditorStates(
      newDays.map(() => ({ morning: "", midday: "", evening: "" }))
    );
  };

  return (
    <div className="add-tour-container">
      {isAdmin ? (
        <>
          <h2 style={{ padding: "20px" }}>เพิ่มทัวร์อื่น ๆ</h2>
          <form>
            <ul className="column-ul">
              <div className="column">
                <div className="input-container">
                  <label>ชื่อทัวร์:</label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    className="input-field"
                  />
                </div>
                <div className="input-container">
                  <label>ราคา:</label>
                  <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleInputChange}
                    className="input-field"
                  />
                </div>
                <div className="input-container">
                  <label>โลโก้:</label>
                  <select
                    type="text"
                    name="logo"
                    value={formData.logo}
                    onChange={handleInputChange}
                    className="input-field select-field"
                  >
                    <option value="">สายการบิน</option>
                    <option value="THAI">การบินไทย</option>
                    <option value="ANA">ANA</option>
                    <option value="AIRASIAX">AirAsiaX</option>
                    <option value="ZIPAIR">ZIPAIR</option>
                    <option value="JAL">Japan Airline</option>
                  </select>
                </div>
                <div className="input-container">
                  <label>เลือกจำนวนมื้ออาหาร:</label>
                  <select
                    type="number"
                    name="meal"
                    value={formData.meal}
                    onChange={handleInputChange}
                    className="input-field select-field"
                  >
                    <option value="1">จำนวนมื้อ</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                  </select>
                </div>
              </div>
              <div className="column">
                <div className="input-container highlight-container">
                  <label>ไฮไลท์:</label>
                  <ReactQuill
                    value={highlight}
                    onChange={handleHighlightChange}
                    className="quill-editor"
                  />
                </div>
              </div>
              <div>
                <h2>Image Upload</h2>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />

                <p>{uploadStatus}</p>
              </div>
              <div>
                <h2>PDF Upload</h2>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handlePDFChange}
                />

                <p>{uploadStatus}</p>
              </div>
              <div className="input-container">
                <label>จำนวนคืน:</label>
                <select
                  name="night"
                  value={formData.night}
                  onChange={handleInputChange}
                  className="input-field select-field"
                >
                  <option value="">เลือกจำนวนคืน...</option>
                  <option value="1">1 คืน</option>
                  <option value="2">2 คืน</option>
                  <option value="3">3 คืน</option>
                  <option value="4">4 คืน</option>
                  <option value="5">5 คืน</option>
                  <option value="6">6 คืน</option>
                  <option value="7">7 คืน</option>
                  <option value="8">8 คืน</option>
                  <option value="9">9 คืน</option>
                  <option value="10">10 คืน</option>
                  <option value="11">11 คืน</option>
                  <option value="12">12 คืน</option>
                  <option value="13">13 คืน</option>
                  <option value="14">14 คืน</option>
                  <option value="15">15 คืน</option>
                </select>
              </div>
              <div className="column">
                <div className="input-container">
                  <label>เลือกจำนวนวัน:</label>
                  <select
                    name="day"
                    value={formData.day}
                    onChange={handleDayChange}
                    className="input-field select-field"
                  >
                    <option value="">เลือกจำนวนวัน...</option>
                    <option value="1">1 วัน</option>
                    <option value="2">2 วัน</option>
                    <option value="3">3 วัน</option>
                    <option value="4">4 วัน</option>
                    <option value="5">5 วัน</option>
                    <option value="6">6 วัน</option>
                    <option value="7">7 วัน</option>
                    <option value="8">8 วัน</option>
                    <option value="9">9 วัน</option>
                    <option value="10">10 วัน</option>
                    <option value="11">11 วัน</option>
                    <option value="12">12 วัน</option>
                    <option value="13">13 วัน</option>
                    <option value="14">14 วัน</option>
                    <option value="15">15 วัน</option>
                  </select>
                </div>
                {formData.days.map((day, dayIndex) => (
                  <div key={dayIndex}>
                    <h3>วันที่ {dayIndex + 1}</h3>
                    <div className="input-container">
                      <label>ช่วงเช้า:</label>
                      <ReactQuill
                        value={dayEditorStates[dayIndex]["morning"]}
                        onChange={(value) =>
                          handleDayEditorChange(value, dayIndex, "morning")
                        }
                        className="quill-editor"
                      />
                    </div>
                    <div className="input-container">
                      <label>กลางวัน:</label>
                      <ReactQuill
                        value={dayEditorStates[dayIndex]["midday"]}
                        onChange={(value) =>
                          handleDayEditorChange(value, dayIndex, "midday")
                        }
                        className="quill-editor"
                      />
                    </div>
                    <div className="input-container">
                      <label>ช่วงเย็น:</label>
                      <ReactQuill
                        value={dayEditorStates[dayIndex]["evening"]}
                        onChange={(value) =>
                          handleDayEditorChange(value, dayIndex, "evening")
                        }
                        className="quill-editor"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </ul>
            <button type="button" className="add-button" onClick={handleAdd}>
              เพิ่มทัวร์
            </button>
          </form>
        </>
      ) : (
        <p>You are not authorized to access this page.</p>
      )}
    </div>
  );
};

export default FbAddTour;
