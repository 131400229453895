import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import roomData from "../common/rooms";
import StarSVG from "../common/star";
import "./room.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi, faCar, faCutlery } from "@fortawesome/free-solid-svg-icons";
import Breadcrumbs from "../common/breadCrumbs";

const Room = () => {
  const { hotel, guesthouse } = roomData;
  const navigate = useNavigate();
  const [breadcrumbs, setBreadcrumbs] = useState([
    { label: "หน้าหลัก", path: "/" },
    { label: "บริการห้องพัก", path: "/room" },
  ]);

  const imageContext = require.context(
    "../../assets/photo/Rooms/Hotel",
    false,
    /\.(png|jpe?g|svg)$/
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const imageName = imageContext
    .keys()
    .map((imageName) => imageName.replace(/^\.\//, ""));

  const handleRoomClick = (room) => {
    navigate(`/roomform/${room.id}`);
    window.scrollTo(0, 0);
  };

  const handleBreadcrumbClick = (path) => {
    navigate(path);
  };

  return (
    <div>
      <div className="container-main">
        <div className="room-container">
          <div className="room-header">
            <Breadcrumbs
              breadcrumbs={breadcrumbs}
              onBreadcrumbClick={handleBreadcrumbClick}
            />
          </div>
          <div className="container-card">
            <div>
              {hotel.map((room) => (
                <div
                  key={room.id}
                  className="room-card"
                  onClick={() => handleRoomClick(room)}
                >
                  <div className="room-big-images-container">
                    <img
                      src={require(`../../assets/photo/Rooms/Hotel/Cover/Cover.jpg`)}
                      alt={room.Model}
                      className="room-big-image"
                    />
                    <div className="room-small-images">
                      {imageName.slice(0, 5).map((item, index) => (
                        <div>
                          <img
                            key={index}
                            src={require(`../../assets/photo/Rooms/Hotel/${item}`)}
                            className="room-small-image"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  {hotel.map((hotel) => (
                    <div className="room-details-card">
                      <h2>{hotel.Model}</h2>
                      <div className="star-container">
                        {StarSVG}
                        {StarSVG}
                        {StarSVG}
                      </div>
                      <div className="feature-box-container">
                        <div className="feature-box">
                          <FontAwesomeIcon className="icon" icon={faCar} />
                          <div className="text">Parking</div>
                          <FontAwesomeIcon className="icon" icon={faWifi} />
                          <div className="text">Free Wifi</div>
                          <FontAwesomeIcon className="icon" icon={faCutlery} />
                          <div className="text">ห้องครัว</div>
                        </div>
                        <div className="feature-box"></div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>

            {guesthouse.map((room) => (
              <div
                key={room.id}
                className="room-card"
                onClick={() => handleRoomClick(room)}
              >
                <div className="room-big-images-container">
                  <img
                    src={require(`../../assets/photo/Rooms/Guesthouse/Cover/Cover.jpg`)}
                    alt={room.Model}
                    className="room-big-image"
                  />
                  <div className="room-small-images">
                    {imageName.slice(0, 5).map((item, index) => (
                      <div>
                        <img
                          key={index}
                          src={require(`../../assets/photo/Rooms/Guesthouse/${item}`)}
                          className="room-small-image"
                        />
                      </div>
                    ))}
                  </div>
                </div>
                {guesthouse.map((guest) => (
                  <div className="room-details-card">
                    <h2>{guest.Model}</h2>
                    <div className="star-container">
                      {StarSVG}
                      {StarSVG}
                    </div>
                    <div className="feature-box-container">
                      <div className="feature-box">
                        <FontAwesomeIcon className="icon" icon={faCar} />
                        <div className="text">Parking</div>
                        <FontAwesomeIcon className="icon" icon={faWifi} />
                        <div className="text">Free Wifi</div>
                        <FontAwesomeIcon className="icon" icon={faCutlery} />
                        <div className="text">ห้องครัว</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Room;
