import React, { useEffect, useState } from "react";
import axios from "axios";
import { rooms } from "../common/rooms";
import { useParams, useNavigate } from "react-router-dom";
import ImageEnlargement from "./imageEnlargement";
import { Modal, Button } from "react-bootstrap";
import { formatDateThai } from "../../components/common/formatDateThai";
import {
  FaBath,
  FaBed,
  FaBus,
  FaCar,
  FaFish,
  FaHome,
  FaHotel,
  FaPlane,
  FaRegBuilding,
  FaSmokingBan,
  FaTree,
  FaTshirt,
  FaUtensils,
  FaWifi,
  FaWind,
} from "react-icons/fa";
import "./roomForm.css";
import Breadcrumbs from "../common/breadCrumbs";
import CustomDatePicker from "../common/customDatePicker";
import Swal from "sweetalert2";

const RoomForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const roomArray = rooms[id.toLowerCase()];
  const url = process.env.REACT_APP_API_URL;
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    tel: "",
    moreDetail: "",
    line: "",
    tour: ``,
    start: new Date(),
    end: new Date(),
  });

  const [breadcrumbs, setBreadcrumbs] = useState([
    { label: "หน้าหลัก", path: "/" },
    { label: "บริการห้องพัก", path: "/room" },
    { label: "รายละเอียด", path: `/roomform/${roomArray[0].id}` },
  ]);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const numberOfDays =
    startDate && endDate
      ? Math.abs(Math.ceil((endDate - startDate) / (24 * 60 * 60 * 1000)))
      : null;

  const imageContext = require.context(
    `../../assets/photo/Rooms/Hotel`,
    false,
    /\.(png|jpe?g|svg)$/
  );

  const imageName = imageContext
    .keys()
    .map((imageName) => imageName.replace(/^\.\//, ""));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setFormData({ ...formData, start: date });
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setFormData({ ...formData, end: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleSubmitModal = async (e) => {
    e.preventDefault();
    const formDataToSend = {
      ...formData,
      tour: `${roomArray[0].id}`,
    };
    try {
      await axios.post(`${url}formdetail`, formDataToSend);

      setShowModal(false);

      Swal.fire({
        icon: "success",
        title: "เรียบร้อย!!",
        text: "เราจะทำการติดต่อกลับให้เร็วที่สุด",
        customClass: {
          popup: "custom-sweetalert",
          confirmButton: "custom-confirm-button",
        },
        buttonsStyling: true,
        confirmButtonText: "ตกลง",
      });
    } catch (error) {
      console.error("Error submitting form data:", error);

      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: "เกิดข้อผิดพลาดในการส่งข้อมูลแบบฟอร์ม กรุณาลองอีกครั้ง",
        customClass: {
          popup: "custom-sweetalert",
          confirmButton: "custom-confirm-button",
        },
        buttonsStyling: true,
        confirmButtonText: "ตกลง",
      });
    }
    setFormData({
      name: "",
      surname: "",
      email: "",
      tel: "",
      moreDetail: "",
      line: "",
      tour: ``,
      start: "",
      end: "",
    });
  };

  const formatDescription = (description) => {
    return description.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
        <br />
      </React.Fragment>
    ));
  };

  const handleBreadcrumbClick = (path) => {
    navigate(path);
  };

  return (
    <div>
      <div className="container">
        <div className="room-container">
          <div className="room-header">
            <Breadcrumbs
              breadcrumbs={breadcrumbs}
              onBreadcrumbClick={handleBreadcrumbClick}
            />
          </div>
          <div className="map-img-container">
            <div className="images-container">
              <img
                src={require(`../../assets/photo/Rooms/${id}/Cover/Cover.jpg`)}
                alt="big Image"
                className="big-image"
                onClick={() => handleImageClick(`Cover/Cover.jpg`)}
              />
              <div className="small-images-container">
                {imageName.slice(0, 6).map((item, index) => (
                  <img
                    src={require(`../../assets/photo/Rooms/${id}/${item}`)}
                    alt="small Image"
                    className="small-image"
                    onClick={() => handleImageClick(`${item}`)}
                    key={item}
                  />
                ))}
              </div>
            </div>
            <div className="map-container">
              <ul className="no-bullets">
                <li>
                  <i
                    className="fa fa-map-marker room-icon"
                    style={{ color: "#d04a4a", fontSize: "24px" }}
                  ></i>
                  {roomArray[0].pin}
                </li>
              </ul>

              <iframe
                src={roomArray[0].src}
                className="maps"
                style={{ border: "0" }}
              />
            </div>
          </div>
          <div className="room-container-main">
            <div className="room-content-form-container">
              <div className="flex-container">
                {roomArray &&
                  roomArray.map((item, index) => (
                    <div className="room-content-container" key={index}>
                      <div className="content-room" key={item.id}>
                        {formatDescription(item.description)}
                      </div>
                    </div>
                  ))}
                {roomArray &&
                  roomArray.map((item, index) => (
                    <div className="room-highlight-container">
                      {item.highlight.map((highlightItem, highlightIndex) => (
                        <div className="room-highlight">
                          <img
                            className="images-highlight"
                            src={require(`../../assets/photo/Logo/${highlightItem.path}`)}
                            alt="Your Image"
                          />
                          <p>{highlightItem.description}</p>
                        </div>
                      ))}
                    </div>
                  ))}{" "}
                <div className="container-form-detail">
                  <div className="form-container">
                    <form className="room-form" onSubmit={handleSubmit}>
                      <div className="head-detail">
                        <h3>รายละเอียดสำหรับการติดต่อกลับ</h3>
                      </div>
                      <ul>
                        <div className="form-group">
                          <label htmlFor="dateIn">วันเข้าพัก</label>
                          <CustomDatePicker
                            name="start"
                            className="form-control date-input"
                            startDateProp={startDate}
                            onChangeStart={handleStartDateChange}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="dateOut">วันเช็คเอาท์</label>
                          <CustomDatePicker
                            name="end"
                            className="form-control date-input"
                            startDateProp={endDate}
                            onChangeEnd={handleEndDateChange}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>

                        <div className="form-group">
                          <label>จำนวนวัน</label>{" "}
                          <input
                            value={numberOfDays || 0}
                            className="form-control custom-input"
                            readOnly
                          />
                        </div>
                      </ul>
                      <ul>
                        <div className="form-group">
                          <label htmlFor="name">ชื่อ</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            onInvalid={(e) =>
                              e.target.setCustomValidity("กรุณากรอกชื่อ")
                            }
                            onInput={(e) => e.target.setCustomValidity("")}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="surname">นามสกุล</label>
                          <input
                            type="text"
                            className="form-control"
                            id="surname"
                            name="surname"
                            value={formData.surname}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="form-group">
                          <div className="input-label">
                            <label htmlFor="tour">ประเภทห้องพัก</label>
                          </div>
                          <div className="cursor-pointer">
                            <input
                              type="tour"
                              className="form-control custom-input"
                              id="tour"
                              name="tour"
                              defaultValue={roomArray[0].id}
                              readOnly
                            />
                          </div>
                        </div>
                      </ul>
                      <ul>
                        <div className="form-group">
                          <label htmlFor="tel">เบอร์โทรศัพท์</label>
                          <input
                            type="text"
                            className="form-control"
                            id="tel"
                            name="tel"
                            value={formData.tel}
                            onChange={handleChange}
                            required
                            onInvalid={(e) =>
                              e.target.setCustomValidity(
                                "กรุณากรอกเบอร์โทรศัพท์"
                              )
                            }
                            onInput={(e) => e.target.setCustomValidity("")}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="line">ไอดีไลน์</label>
                          <input
                            type="text"
                            className="form-control"
                            id="line"
                            name="line"
                            value={formData.line}
                            onChange={handleChange}
                            required
                            onInvalid={(e) =>
                              e.target.setCustomValidity("กรุณากรอกไอดีไลน์")
                            }
                            onInput={(e) => e.target.setCustomValidity("")}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">อีเมลล์</label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                      </ul>
                      <div className="form-group-room">
                        <label htmlFor="moreDetail">ข้อมูลเพิ่มเติม</label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="moreDetail"
                          name="moreDetail"
                          value={formData.moreDetail}
                          onChange={handleChange}
                          rows="5"
                        ></textarea>
                      </div>
                      <div className="submit-button-container">
                        <button className="btn-detail" type="submit">
                          ส่งข้อมูล
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="room-details-container">
              {roomArray &&
                roomArray.map((item, index) => (
                  <div className="room-details" key={index}>
                    <ul className="no-bullets">
                      <h3 style={{ marginTop: 0 }}>สิ่งอำนวยความสะดวก</h3>
                      <li>
                        <i className="room-icon">
                          <FaHotel />
                        </i>
                        {item.detail.area}
                      </li>
                      <li>
                        <i className="room-icon">
                          <FaWifi />
                        </i>
                        {item.detail.wifi}
                      </li>
                      <li>
                        <i className="room-icon">
                          <FaBath />
                        </i>
                        {item.detail.bath}
                      </li>
                      <li>
                        <i className="room-icon">
                          <FaUtensils />
                        </i>
                        {item.detail.cook}
                      </li>
                      <li>
                        <i className="room-icon">
                          <FaTshirt />
                        </i>
                        {item.detail.laundry}
                      </li>
                      <li>
                        <i className="room-icon">
                          <FaWind />
                        </i>
                        {item.detail.air}
                      </li>

                      <li>
                        <i className="room-icon">
                          <FaCar />
                        </i>
                        {item.detail.park}
                      </li>
                      <li>
                        <i className="room-icon">
                          <FaSmokingBan />
                        </i>
                        {item.detail.smoke}
                      </li>
                      <li>
                        <i className="room-icon">
                          <FaBed />
                        </i>
                        {item.detail.room1}
                      </li>
                      <li>
                        <i className="room-icon">
                          <FaBed />
                        </i>
                        {item.detail.room2}
                      </li>
                    </ul>
                    {item.around && (
                      <ul className="no-bullets">
                        <h3>
                          <i className="room-icon">
                            <FaHome />
                          </i>
                          สถานที่ใกล้เคียง
                        </h3>

                        <div className="around-items">
                          <li>
                            {item.around.p1}&nbsp;&nbsp;&nbsp;&nbsp;
                            <strong>{item.distance.p1}</strong>
                          </li>
                          <li>
                            {item.around.p2}&nbsp;&nbsp;&nbsp;&nbsp;
                            <strong>{item.distance.p2}</strong>
                          </li>
                          <li>
                            {item.around.p3}&nbsp;&nbsp;&nbsp;&nbsp;
                            <strong>{item.distance.p3}</strong>
                          </li>
                          <li>
                            {item.around.p4}&nbsp;&nbsp;&nbsp;&nbsp;
                            <strong>{item.distance.p4}</strong>
                          </li>
                        </div>

                        <h3>
                          <i className="room-icon">
                            <FaFish />
                          </i>
                          ร้านอาหาร
                        </h3>

                        <li>
                          {item.around.p5}&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{item.distance.p5}</strong>
                        </li>
                        <li>
                          {item.around.p6}&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{item.distance.p6}</strong>
                        </li>

                        <h3>
                          {" "}
                          <i className="room-icon">
                            <FaRegBuilding />
                          </i>
                          สิ่งที่น่าสนใจยอดนิยม
                        </h3>

                        <li>
                          {item.around.p7}&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{item.distance.p7}</strong>
                        </li>
                        <li>
                          {item.around.p8}&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{item.distance.p8}</strong>
                        </li>
                        <li>
                          {item.around.p9}&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{item.distance.p9}</strong>
                        </li>

                        <h3>
                          <i className="room-icon">
                            <FaTree />
                          </i>
                          แหล่งธรรมชาติ
                        </h3>

                        <li>
                          {item.around.p10}&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{item.distance.p10}</strong>
                        </li>

                        <h3>
                          {" "}
                          <i className="room-icon">
                            <FaBus />
                          </i>
                          ขนส่งสาธารณะ
                        </h3>

                        <li>
                          {item.around.p11}&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{item.distance.p11}</strong>
                        </li>
                        <li>
                          {item.around.p12}&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{item.distance.p12}</strong>
                        </li>

                        <h3>
                          <i className="room-icon">
                            <FaPlane />
                          </i>
                          สนามบินที่ใกล้ที่สุด
                        </h3>

                        <li>
                          {item.around.p13}&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{item.distance.p13}</strong>
                        </li>
                        <li>
                          {item.around.p14}&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{item.distance.p14}</strong>
                        </li>
                        <li>
                          {item.around.p15}&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{item.distance.p15}</strong>
                        </li>
                      </ul>
                    )}
                  </div>
                ))}
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>ข้อมูลการจอง</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <span className="label-data">ชื่อ: </span>
                  {formData.name}
                </p>
                <p>
                  <span className="label-data">นามสกุล: </span>
                  {formData.surname}
                </p>
                <p>
                  <span className="label-data">เช็คอิน: </span>
                  {formData.start && formatDateThai(formData.start)}
                </p>
                <p>
                  <span className="label-data"> เช็คเอาท์: </span>
                  {formData.end && formatDateThai(formData.end)}
                </p>
                <p>
                  <span className="label-data">ไอดีไลน์: </span>
                  {formData.line}
                </p>
                <p>
                  <span className="label-data">เบอร์โทรศัพท์: </span>
                  {formData.tel}
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn-detail-modal-cancel"
                  variant="secondary"
                  onClick={() => setShowModal(false)}
                >
                  ยกเลิก
                </Button>
                <Button
                  className="btn-detail-modal"
                  variant="primary"
                  onClick={handleSubmitModal}
                >
                  ยืนยัน
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        {showModal && <Modal />}
        {selectedImage !== null && (
          <ImageEnlargement
            selectedImage={selectedImage}
            onClose={() => setSelectedImage(null)}
          />
        )}
      </div>
    </div>
  );
};

export default RoomForm;
