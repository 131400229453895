import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./common.css";

const ImageCarousel = () => {
  const imageContext = require.context(
    "../../assets/photo/Impression",
    false,
    /\.(png|jpe?g|svg)$/
  );

  const imageName = imageContext
    .keys()
    .map((imageName) => imageName.replace(/^\.\//, ""));

  return (
    <div className="container-carousel">
      <div id="carousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          {imageName.slice(0, imageName.length).map((item, index) => (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
              data-bs-interval="3000"
            >
              <div className="">
                <img
                  src={require(`../../assets/photo/Impression/${item}`)}
                  className="img-fluid"
                  alt={`Slide ${index}`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="carousel-text">
        <p>
          Thanyaporn tours รับจัดกรุ๊ปทัวร์ญี่ปุ่นคุณภาพ ท่องเที่ยว
          ทัวร์ทัศนศึกษาดูงาน เที่ยวสังสรรค์สัมมนา ประชุม
          ตามเส้นทางที่สนใจและงบประมาณที่พอใจ
          พาท่องเที่ยวสถานที่ยอดนิยมทุกแบบทุกเส้นทางทั่วญี่ปุ่น
          ค้นหาโรงแรมที่พักและร้านอาหารนิยม ให้ท่านได้เพลิดเพลินตลอดการเดินทาง
          บริการตั้งแต่เที่ยวแบบเรียบง่าย สบายๆจนถึงแบบหรูหรา
          บริการได้ทุกระดับโดยคำนึงถึงความต้องการของลูกค้าเป็นหลักสำคัญ
          <br />
          <br />
          <br />
          ด้วยประสบการณ์ด้านท่องเที่ยวมายาวนาน
          ทำให้เรามั่นใจในบริการและความเชี่ยวชาญในการจัดกรุ๊ปทัวร์ให้กับลูกค้าทุกท่าน
          มีทีมงานประสานงานทั้งที่ไทยและญี่ปุ่น
          วางใจได้ในการติดต่อที่ทั้งคล่องตัว รวดเร็ว ให้ท่านได้สบายใจ
          และเที่ยวได้อย่างสะดวกสบาย
        </p>
      </div>
    </div>
  );
};

export default ImageCarousel;
